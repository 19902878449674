import { REGISTER_USER_SUCCESS, UNREGISTER_USER_SUCCESS } from 'app/users/reducers/current';

const initialState = null;

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case REGISTER_USER_SUCCESS: {
      return payload.user.token;
    }
    case UNREGISTER_USER_SUCCESS: {
      return null;
    }
    default:
      return state;
  }
}
