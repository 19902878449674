import { post } from 'core/helpers/fetch';
import {
  END_HANGEDMAN_ROUND_STARTED,
  END_HANGEDMAN_ROUND_SUCCEED,
  END_HANGEDMAN_ROUND_FAILED,
} from 'app/games/hangedman/reducers/instances';

export default function endRound(instanceId, word) {
  return async dispatch => {
    dispatch({ type: END_HANGEDMAN_ROUND_STARTED, payload: { instanceId: instanceId, word }});
    try {
      const instance = await dispatch(
        post(`/games/hangedman/instances/${instanceId}/endRound`)
      );
      dispatch({
        type: END_HANGEDMAN_ROUND_SUCCEED,
        payload: {
          instance,
        }
      });
    } catch (exception) {
      dispatch({ type: END_HANGEDMAN_ROUND_FAILED, payload: { instanceId: instanceId, exception } });
      throw exception;
    }
  };
}
