import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from "react-i18next";
import registerUser from 'app/users/actions/registerUser'
import Form from 'app/users/login/form';
import Welcome from 'app/users/login/form/welcome';
import Errors from 'app/users/login/form/errors';
import Submit from 'app/users/login/form/submit';
import BottomLinks from 'app/users/login/form/bottomLinks';
import 'app/users/login/index.css';
import {PAGE_LOGIN_BY_CREDENTIALS} from "app/users/login/loginByCredentials";
import {PAGE_CREATE_CREDENTIALS} from "app/users/login/createCredentials";

export const PAGE_LOGIN_BY_NAME = 'loginByName';

const LoginByName = ({ changePage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ error, setError ] = useState(null);
  const [ inputUsername, setInputUsername ] = useState('');

  const submitRegister = async () => {
    try {
      await dispatch(registerUser({ name: inputUsername }));
    } catch (exception) {
      setError(exception.message);
    }
  };

  const changeUsername = event => {
    setInputUsername(event.target.value);
    setError(null);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      submitRegister();
    }
  };

  const goToLoginByCredentials = event => {
    event.preventDefault();
    changePage(PAGE_LOGIN_BY_CREDENTIALS);
  };

  return (
    <div className="LoginByName panel padded">
      <Welcome className="LoginWelcome">
        {t('login.welcomeLoginByName')}
      </Welcome>
      <Form inline={true}>
        <input
          className="LoginNameInput"
          type="text"
          value={inputUsername}
          onChange={changeUsername}
          onKeyPress={handleKeyPress}
        />
        <Submit onClick={submitRegister}>{t('login.submit')}</Submit>
      </Form>
      {error && <Errors>{error}</Errors>}
      <BottomLinks>
        <div>
          <Trans i18nKey="login.loginWithCredentials">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            Vous pouvez également <a href="" onClick={goToLoginByCredentials}>vous connecter</a> avec vos identifiants ou <a onClick={() => changePage(PAGE_CREATE_CREDENTIALS)}>créer un compte.</a>
          </Trans>
        </div>
      </BottomLinks>
    </div>
  );
};

export default LoginByName;
