import React from 'react';
import 'app/users/login/form/field.css';

const Field = ({ label, children }) => (
  <div className="LoginFormField">
    <div className="LoginFormFieldLabel">
      {label}
    </div>
    <div className="LoginFormFieldInput">
      {children}
    </div>
  </div>
);

export default Field;
