export const TABOO_INSTANCE_FILTER_DIFFICULTY_SELECTED = 'games.taboo.instance.filter.difficulty.selected'
export const TABOO_INSTANCE_FILTER_CATEGORY_SELECTED = 'games.taboo.instance.filter.category.selected';
export const TABOO_INSTANCE_FILTER_RESET = 'games.taboo.instance.filter.reset';

export const TABOO_FILTER_TYPE_DIFFICULTY = 'difficulty';
export const TABOO_FILTER_TYPE_CATEGORY = 'category';

const initialState = {};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case TABOO_INSTANCE_FILTER_DIFFICULTY_SELECTED:
      return {
        ...state,
        [payload.instanceId]: {
          type: TABOO_FILTER_TYPE_DIFFICULTY,
          difficultyId: payload.difficultyId,
        },
      };
    case TABOO_INSTANCE_FILTER_CATEGORY_SELECTED:
      return {
        ...state,
        [payload.instanceId]: {
          type: TABOO_FILTER_TYPE_CATEGORY,
          categoryId: payload.categoryId,
        },
      };
    case TABOO_INSTANCE_FILTER_RESET:
      return {
        ...state,
        [payload.instanceId]: null,
      };
    default:
      return state;
  }
}
