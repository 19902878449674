import config from 'config';

let socket = null;

function createWebsocket() {
  if (socket !== null) {
    socket.close();
  }

  socket = new WebSocket(config.websocket.url);
  console.debug('[Websocket] Connected.');

  let pingTimeout = null;

  const heartbeat = () => {
    console.debug('[Websocket] Server is alive!');
    clearTimeout(pingTimeout);

    // Use `WebSocket#terminate()`, which immediately destroys the connection,
    // instead of `WebSocket#close()`, which waits for the close timer.
    // Delay should be equal to the interval at which your server
    // sends out pings plus a conservative assumption of the latency.
    pingTimeout = setTimeout(() => {
      console.debug('[Websocket] Server connection closed (timeout).');
      socket.close();
    }, config.websocket.ping_interval);
  };

  const onClose = () => {
    clearTimeout(pingTimeout);
    if (socket !== null) {
      socket.close();
    }
    socket = null;
  };

  socket.addEventListener('message', heartbeat);
  socket.addEventListener('ping', heartbeat);
  socket.addEventListener('close', onClose);

  return socket;
}

export default createWebsocket;
