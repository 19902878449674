import React from 'react'
import ModalContent from 'app/modal/content';
import 'app/admin/whoisit/cards/modal/image.css';

function CardImage({ name, image, closeModal }) {
  return (
    <ModalContent title={name} closeModal={closeModal} loading={false} contentClassName="CardImageContainer">
      <img className="CardImage" src={image} alt={name} />
    </ModalContent>
  );
}

export default CardImage;
