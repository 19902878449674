import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import isCurrentUserAdmin from 'app/users/selectors/isCurrentUserAdmin';
import NotFound from 'app/notFound';
import Taboo from 'app/games/taboo';
import TabooCreateInstance from 'app/games/taboo/createInstance';
import Whoisit from "app/games/whoisit";
import WhoIsItCreateInstance from 'app/games/whoisit/createInstance';
import Hangedman from "app/games/hangedman";
import HangedManCreateInstance from 'app/games/hangedman/createInstance';

import Admin from 'app/admin';
import Games from 'app/games';

export const PATH_GAMES = '/games';
export const PATH_GAMES_TABOO_NEW = '/games/taboo/new';
export const PATH_GAMES_TABOO_INSTANCE = '/games/taboo/:instanceId';
export const PATH_GAMES_WHOISIT_NEW = '/games/whoisit/new';
export const PATH_GAMES_WHOISIT_INSTANCE = '/games/whoisit/:instanceId';
export const PATH_GAMES_HANGEDMAN_NEW = '/games/hangedman/new';
export const PATH_GAMES_HANGEDMAN_INSTANCE = '/games/hangedman/:instanceId';


export const PATH_ADMIN = '/admin';

const AdminRoute = ({ path, component }) => {
  const isAdmin = useSelector(isCurrentUserAdmin);

  return isAdmin ? <Route path={path} component={component} /> : <NotFound />;
};

export default function Routes() {
  return (
    <Switch>
      <AdminRoute path={PATH_GAMES_TABOO_NEW} component={TabooCreateInstance} />
      <AdminRoute path={PATH_GAMES_WHOISIT_NEW} component={WhoIsItCreateInstance} />
      <AdminRoute path={PATH_GAMES_HANGEDMAN_NEW} component={HangedManCreateInstance} />
      <Route path={PATH_GAMES_TABOO_INSTANCE} component={Taboo} />
      <Route path={PATH_GAMES_WHOISIT_INSTANCE} component={Whoisit} />
      <Route path={PATH_GAMES_HANGEDMAN_INSTANCE} component={Hangedman} />
      <Route path={PATH_GAMES} component={Games} />
      <AdminRoute path={PATH_ADMIN} component={Admin} />
      <Route exact path="/">
        <Redirect to={PATH_GAMES} />
      </Route>
      <Route component={NotFound} />
    </Switch>
  );
}
