import React from 'react';
import { ReactComponent as IconCheckbox } from 'assets/icon/checkedbox.svg';
import 'app/notifications/error.css';

const Success = ({ children }) => (
  <>
    <IconCheckbox className="NotificationsSuccessIcon" />
    <div>{children}</div>
  </>
);

export default Success;
