import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useParamSelector from 'core/redux/hooks/useParamSelector';
import pickInstanceCard from 'app/games/taboo/actions/pickInstanceCard';
import getInstanceRemainingCardsCount from 'app/games/taboo/selectors/getInstanceRemainingCardsCount';
import getCurrentUserCard from 'app/games/taboo/selectors/getCurrentUserCard';
import isPickingCardByInstance from 'app/games/taboo/selectors/isPickingCardByInstance';
import { ReactComponent as IconCards } from 'assets/icon/cards.svg';
import { ReactComponent as IconSettings } from 'assets/icon/settings.svg';
import TabooCard from 'app/games/taboo/card';
import 'app/games/taboo/game.css';

function Game({ instance, filter, changeFilter }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const remainingCardsCount = useParamSelector(getInstanceRemainingCardsCount, instance.id, filter);
  const hasRemainingCards = remainingCardsCount !== 0;

  const currentCard = useParamSelector(getCurrentUserCard, instance.id, filter);
  const isPickingCard = useParamSelector(isPickingCardByInstance, instance.id);


  const handlePickCard = () => dispatch(pickInstanceCard(instance.id, filter));

  return (
    <div className="TabooGame">
      <div className="TabooBoard">
        <div className="TabooControls">
          <div className="TabooPickCard">
            <button className="TabooPickCardButton button xlarge primary" onClick={handlePickCard} disabled={isPickingCard || !hasRemainingCards}>
              <IconCards className="TabooPickCardButtonIcon" />
              {t('games.taboo.pickCard')}
            </button>
            <div className="TabooAvailableCardsCount">
              {remainingCardsCount > 0
                ? t('games.taboo.cardsToPickCount', { count: remainingCardsCount })
                : t('games.taboo.cardsToPickCount_zero')
              }
            </div>
          </div>
          <button className="TabooChangeFilterButton button large secondary" onClick={changeFilter}>
            <IconSettings className="TabooChangeFilterButtonIcon" />
            {t('games.taboo.changeFilter')}
          </button>
        </div>
        <div className="TabooPickedCard">
          {currentCard && <TabooCard card={isPickingCard ? null : currentCard} />}
        </div>
      </div>
    </div>
  );
}

export default Game;
