export const FETCH_CATEGORIES_START = 'categories.fetch.start';
export const FETCH_CATEGORIES_SUCCESS = 'categories.fetch.success';
export const FETCH_CATEGORIES_ERROR = 'categories.fetch.error';

const initialState = [];

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_CATEGORIES_SUCCESS: {
      const { categories } = payload;
      return categories;
    }
    default:
      return state;
  }
}
