import getHostname from 'core/helpers/getHostname';

const host = getHostname();

const config = {
  server: {
    url: `https://${host}:3001`,
  },
  websocket: {
    url: `wss://${host}:3001`,
    ping_interval: 11000
  },
  bugsnag: {
    apiKey: '53e523c25bc6224a7799b36a2f5d369b',
  }
};

export default config;
