import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from "i18next-browser-languagedetector";
import translationsFr from 'locales/fr';
import translationsDe from 'locales/de';

function bootstrapI18next() {
  return i18next
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        fr: {
          translation: translationsFr
        },
        'fr-FR': {
          translation: translationsFr
        },
        de: {
          translation: translationsDe
        },
        'de-DE': {
          translation: translationsDe
        },
      },
      whitelist: ['fr', 'de'],
      fallbackLng: 'fr',
      keySeparator: false,
      interpolation: {
        escapeValue: false
      },
      detection: {
        caches: ['localStorage', 'cookie'],
        excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
        checkWhitelist: true,
      },
    });
}

export default bootstrapI18next;
