import { useTranslation } from 'react-i18next';
import 'app/games/taboo/gameTitle.css';

const GameTitle = () => {
  const { t } = useTranslation();

  return (
    <div className="TabooGameTitle">
      <div className="TabooGameTitleText">
        {t('games.taboo.title')}
      </div>
    </div>
  );
};

export default GameTitle;
