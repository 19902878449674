import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter as Router } from 'react-router-dom';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import * as serviceWorker from 'serviceWorker';
import bootstrap from 'core/bootstrap';
import createAppStore from 'core/redux/createStore';
import reducers from 'app/reducers';
import App from 'app';

bootstrap().then(() => {
  const { store, persistor } = createAppStore(reducers);
  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <I18nextProvider i18n={i18next}>
            <Router>
              <App />
            </Router>
          </I18nextProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
