import React from "react";
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import classnames from 'classnames';
import 'app/games/tile.css';

const GameTile = ({ to, title, description, disabled, className }) => {
  return (
    <div className={classnames("GameTile tile onPrimary", { disabled }, className)}>
      <Link to={to} className="GameTileLink">
        <div className="GameTileTitle">
          {title}
        </div>
        <div className="GameTileDescription">
          {description}
        </div>
      </Link>
    </div>
  );
};

GameTile.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

GameTile.defaultProps = {
  disabled: false,
  classNames: null,
};

export default GameTile;
