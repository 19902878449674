import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTable, useSortBy, useGlobalFilter, useRowState } from 'react-table';
import { useTranslation } from "react-i18next";
import getCards from 'app/games/whoisit/selectors/getCards';
import Search from 'app/admin/whoisit/cards/table/search';
import DeleteButton from 'app/admin/whoisit/cards/table/deleteButton';
import EditButton from 'app/admin/whoisit/cards/table/editButton';
import ViewImage from 'app/admin/whoisit/cards/table/viewImage';
import 'app/admin/whoisit/cards/index.css';

function normalizeString(string) {
  return string.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase();
}

const AdminWhoisitCards = () => {
  const { t } = useTranslation();
  const cards = useSelector(getCards);

  const data = useMemo(
    () => cards.map(({ id, name, image }) => {
      return ({
        id,
        name,
        image
      })
    }),
    [cards]
  );

  const columns = useMemo(
    () => {
      return [
        {
          Header: t('admin.whoisit.cards.column.id'),
          accessor: ({ id }) => id,
        },
        {
          Header: t('admin.whoisit.cards.column.name'),
          accessor: 'name',
        },
        {
          Header: t('admin.whoisit.cards.column.imageFile'),
          accessor: ({ name, image }) => <ViewImage name={name} image={image} />,
          disableSortBy: true,
        },
        {
          Header: t('admin.whoisit.cards.column.actions'),
          accessor: ({ id }) => <><EditButton id={id} /><DeleteButton id={id} /></>,
          disableSortBy: true,
        },
      ]
    },
    [t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'ID',
            asc: true,
          }
        ]
      },
      autoResetSortBy: false,
      autoResetRowState: false,
      defaultCanSort: true,
      disableSortRemove: true,
      globalFilter: (rows, columns, searchText) => {
        const tokens = normalizeString(searchText)
          .replace(/[,:;/\\[\]()!?.]/g, ' ') // Remove punctuation
          .match(/(\b[^\s]+\b)/g); // Split names

        if (tokens == null) {
          return [];
        }

        const searchFields = ['ID', 'name', 'imageFile'];
        return rows.filter(({ values }) => {
          return tokens.filter(token => searchFields.some(field => {
            return normalizeString(values[field] ?? '').includes(token);
          })).length === tokens.length;
        });
      },
    },
    useGlobalFilter,
    useSortBy,
    useRowState,
  );

  return (
    <div className="AdminWhoisitCards">
      <div className="AdminWhoisitCardsSearch">
        <Search
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                <span className="SortBy" {...column.getSortByToggleProps()}>
                  {column.render('Header')}
                  {column.isSorted
                    ? column.isSortedDesc
                      ? <span className="SortArrow"> ▾</span>
                      : <span className="SortArrow"> ▴</span>
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
    </div>
  );
};

export default AdminWhoisitCards;
