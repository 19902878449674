import React from 'react';
import 'app/users/login/form/welcome.css';

const Welcome = ({ children }) => (
  <div className="LoginWelcome">
    {children}
  </div>
);

export default Welcome;
