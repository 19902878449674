import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import usePromise from 'core/redux/hooks/usePromise';

export default function useActionEffect(action, params = undefined) {
  const dispatch = useDispatch();
  const promise = usePromise();
  const [isRunning, setIsRunning] = useState(true);
  const [exception, setException] = useState(null);
  const [result, setResult] = useState(undefined);

  const boundAction = useCallback(async () => {
    try {
      setResult(await promise(dispatch(action(params))));
    } catch (e) {
      setException(e);
    }
    setIsRunning(false);
  }, [dispatch, promise, setIsRunning, setException, setResult, action, params]);

  useEffect(() => {
    boundAction();
  }, [boundAction]);

  return { isRunning, exception, result };
}
