export const FETCH_CARDS_START = 'cards.fetch.start';
export const FETCH_CARDS_SUCCESS = 'cards.fetch.success';
export const FETCH_CARDS_ERROR = 'cards.fetch.error';

export const CREATE_CARD_SUCCESS = 'cards.create.success';
export const CREATE_CARD_ERROR = 'cards.create.error';
export const UPDATE_CARD_SUCCESS = 'cards.update.success';
export const UPDATE_CARD_ERROR = 'cards.update.error';

export const DELETE_CARD_SUCCESS = 'cards.delete.success';
export const DELETE_CARD_ERROR = 'cards.delete.error';

const initialState = [];

function updateObjectInArray(array, value, predicate) {
  const index = array.findIndex(predicate);
  if (index === -1) {
    return array;
  }

  const newArray = [...array];
  newArray[index] = value;

  return newArray;
}

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_CARDS_SUCCESS: {
      return payload.cards;
    }
    case CREATE_CARD_SUCCESS:
      return [...state, payload.card];
    case UPDATE_CARD_SUCCESS:
      return updateObjectInArray(state, payload.card, ({ id }) => id === payload.card.id);
    case DELETE_CARD_SUCCESS:
      return state.filter(card => card.id !== payload.id);
    default:
      return state;
  }
}
