import React, {useEffect, useState} from "react";
import 'app/games/taboo/card.css';

const ThreeDotsLoading = () => {
  const [count, setCount] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count === 3 ? 1 : count + 1);
    }, 200);
    return () => clearInterval(interval);
  }, [count, setCount]);

  return '.'.repeat(count);
};

const TabooCard = ({ card = null }) => (
  <div className="TabooCard card onSecondary">
    <div className="TabooCardWord">
      {card ? card.word : <ThreeDotsLoading />}
    </div>
    <div className="TabooCardForbidden">
      {card ? card.forbidden.map(forbiddenWord => (
        <div className="TabooCardForbiddenWord" key={forbiddenWord}>{forbiddenWord}</div>
      )) : <ThreeDotsLoading />}
    </div>
  </div>
);

export default TabooCard;
