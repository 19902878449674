import { get } from 'core/helpers/fetch';
import {
  FETCH_CARDS_START,
  FETCH_CARDS_SUCCESS,
  FETCH_CARDS_ERROR,
} from 'app/games/whoisit/reducers/cards';

function fetchCards() {
  return async dispatch => {
    dispatch({ type: FETCH_CARDS_START });
    try {
      const cards = await dispatch(get('/games/whoisit/cards'));
      dispatch({ type: FETCH_CARDS_SUCCESS, payload: { cards } });
      return cards;
    } catch (exception) {
      dispatch({ type: FETCH_CARDS_ERROR, payload: { exception } });
      throw exception;
    }
  };
}

export default fetchCards;
