import { combineReducers } from 'redux';

export default function combineReducersTree(reducersTree) {
  return combineReducers(
    Object.fromEntries(
      Object.entries(reducersTree).map(([ key, value ]) =>
        typeof value === 'object'
          ? [key, combineReducersTree(value)]
          : [key, value]
      )
    )
  );
}
