import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import asyncEffect from 'core/helpers/asyncEffect';

export default function useFetch(action, ...params) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(
    asyncEffect(async () => {
      await dispatch(action(...params));
      setLoading(false);
    }),
    [dispatch]
  );

  return isLoading;
}
