import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import registerUser from 'app/users/actions/registerUser'
import Form from 'app/users/login/form';
import Welcome from 'app/users/login/form/welcome';
import Errors from 'app/users/login/form/errors';
import Submit from 'app/users/login/form/submit';
import BottomLinks from 'app/users/login/form/bottomLinks';
import 'app/users/login/index.css';
import {PAGE_LOGIN_BY_NAME} from 'app/users/login/loginByName';
import {PAGE_CREATE_CREDENTIALS} from 'app/users/login/createCredentials';
import Field from "app/users/login/form/field";

export const PAGE_LOGIN_BY_CREDENTIALS = 'loginByCredentials';

const LoginByCredentials = ({ changePage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ error, setError ] = useState(null);
  const [ inputEmail, setInputEmail ] = useState('');
  const [ inputPassword, setInputPassword ] = useState('');

  const submitRegister = async () => {
    try {
      await dispatch(registerUser({ email: inputEmail, password: inputPassword }));
    } catch (exception) {
      setError(exception.message);
    }
  };

  const handleChangeEmail = event => {
    setInputEmail(event.target.value);
    setError(null);
  };

  const handleChangePassword = event => {
    setInputPassword(event.target.value);
    setError(null);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      submitRegister();
    }
  };

  const goToLoginByName = event => {
    event.preventDefault();
    changePage(PAGE_LOGIN_BY_NAME);
  };

  const goToCreateCredentials = event => {
    event.preventDefault();
    changePage(PAGE_CREATE_CREDENTIALS);
  };

  return (
    <div className="LoginByCredentials panel padded">
      <Welcome>
        {t('login.welcomeLoginByCredentials')}
      </Welcome>
      <Form inline={false}>
        <Field label={t('login.loginByCredentials.email.label')}>
          <input
            className="LoginEmailInput"
            type="text"
            value={inputEmail}
            onChange={handleChangeEmail}
            onKeyPress={handleKeyPress}
          />
        </Field>
        <Field label={t('login.loginByCredentials.password.label')}>
          <input
            className="LoginPasswordInput"
            type="password"
            value={inputPassword}
            onChange={handleChangePassword}
            onKeyPress={handleKeyPress}
          />
        </Field>
        <Submit onClick={submitRegister}>{t('login.submit')}</Submit>
      </Form>
      {error && <Errors>{error}</Errors>}
      <BottomLinks>
        <div>
          <Trans i18nKey="login.loginByCredentials.createCredentials">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            Pas encore d'identifiants ? <a href="" onClick={goToCreateCredentials}>Créer un compte</a>
          </Trans>
        </div>
        <div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="" onClick={goToLoginByName}>{t('login.loginByCredentials.backToLoginByName')}</a>
        </div>
      </BottomLinks>
    </div>
  );
};

export default LoginByCredentials;
