import { STATUS_LOADED, STATUS_LOADING } from 'app/common/status/actions';
import { getMessageActionType } from "core/websocket/redux/middleware";

export const FETCH_WHOISIT_INSTANCE_STARTED = 'games.whoisit.instance.fetch.started';
export const FETCH_WHOISIT_INSTANCE_SUCCEED = 'games.whoisit.instance.fetch.succeed';
export const FETCH_WHOISIT_INSTANCE_FAILED = 'games.whoisit.instance.fetch.failed';

export const CREATE_WHOISIT_INSTANCE_STARTED = 'games.whoisit.instance.create.started';
export const CREATE_WHOISIT_INSTANCE_SUCCEED = 'games.whoisit.instance.create.succeed';
export const CREATE_WHOISIT_INSTANCE_FAILED = 'games.whoisit.instance.create.failed';

export const PICK_WHOISIT_INSTANCE_CARD_STARTED = 'games.whoisit.instance.pickCard.started';
export const PICK_WHOISIT_INSTANCE_CARD_SUCCEED = 'games.whoisit.instance.pickCard.succeed';
export const PICK_WHOISIT_INSTANCE_CARD_FAILED = 'games.whoisit.instance.pickCard.failed';

const WEBSOCKET_WHOISIT_INSTANCE_UPDATED = getMessageActionType('games.whoisit.instance_updated');

function findInstanceIndexById(state, id) {
  return state.findIndex(instance => instance.entity.id === id);
}

function updateInstance(state, instance) {
  const instanceIndex = findInstanceIndexById(state, instance.entity.id);

  if (instanceIndex !== -1) {
    const newState = [...state];
    newState[instanceIndex] = { ...newState[instanceIndex], ...instance };
    return newState;
  }

  return [...state, { ...instance } ];
}

function setInstanceProperty(state, instanceId, property, value) {
  const instanceIndex = findInstanceIndexById(state, instanceId);

  if (instanceIndex === -1) {
    return state;
  }

  const newState = [...state];
  newState[instanceIndex] = { ...newState[instanceIndex], [property]: value };
  return newState;
}

const initialState = [];

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_WHOISIT_INSTANCE_STARTED: {
      return updateInstance(state, {
        status: STATUS_LOADING,
        entity: { id: payload.id },
      });
    }
    case FETCH_WHOISIT_INSTANCE_SUCCEED: {
      return updateInstance(state, {
        status: STATUS_LOADED,
        entity: payload.instance,
      });
    }
    case CREATE_WHOISIT_INSTANCE_SUCCEED:
      return updateInstance(state, {
        status: STATUS_LOADED,
        entity: payload.instance,
      });
    case PICK_WHOISIT_INSTANCE_CARD_STARTED:
     return setInstanceProperty(state, payload.instanceId, 'isPickingCard', true);
    case PICK_WHOISIT_INSTANCE_CARD_SUCCEED:
      return updateInstance(state, {
        entity: payload.instance,
        currentCardId: payload.cardId,
        isPickingCard: false,
      });
    case PICK_WHOISIT_INSTANCE_CARD_FAILED:
     return setInstanceProperty(state, payload.instanceId, 'isPickingCard', false);
    case WEBSOCKET_WHOISIT_INSTANCE_UPDATED:
      return updateInstance(state, {
        status: STATUS_LOADED,
        entity: payload.instance,
      });
    default:
      return state;
  }
}
