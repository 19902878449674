import { put } from 'core/helpers/fetch';
import { UPDATE_CARD_SUCCESS, UPDATE_CARD_ERROR } from 'app/games/taboo/reducers/cards';

function updateCard(id, values) {
  return async dispatch => {
    try {
      const updatedCard = await dispatch(put(`/admin/taboo/cards/${id}`, values));
      dispatch({ type: UPDATE_CARD_SUCCESS, payload: { card: updatedCard }})
    } catch (exception) {
      dispatch({ type: UPDATE_CARD_ERROR, payload: { exception }});
      throw exception;
    }
  };
}

export default updateCard;
