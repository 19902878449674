import { useState, useRef } from 'react';
import useDebounceEffect from "core/react/hooks/useDebounceEffect";
import { centerCrop, makeAspectCrop } from 'react-image-crop'

export class InvalidImageException extends Error {}

const TO_RADIANS = Math.PI / 180

async function canvasPreview(
  image,
  canvas,
  crop,
  scale = 1,
  rotate = 0,
) {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context')
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  ctx.restore();
}

function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

export default function useCardFormImageUploadWithCrop({ aspectRatio = 1, allowedImageTypes = [] }) {
  const [uploadedImagePreviewUrl, setUploadedImagePreviewUrl] = useState('');
  const uploadedImagePreviewRef = useRef(null);
  const uploadedImageCroppedPreviewCanvasRef = useRef(null);
  const [crop, setCrop] = useState();
  const [uploadedImageCropped, setUploadedImageCropped] = useState();

  const getUploadedImagePreviewAsBlob = async () => {
    if (!uploadedImagePreviewUrl) {
      return undefined;
    }

    const uploadedImage = uploadedImagePreviewRef.current;
    const uploadedImageCroppedCanvas = uploadedImageCroppedPreviewCanvasRef.current;
    if (!uploadedImage || !uploadedImageCroppedCanvas || !uploadedImageCropped) {
      throw new Error('Crop canvas does not exist')
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = uploadedImage.naturalWidth / uploadedImage.width;
    const scaleY = uploadedImage.naturalHeight / uploadedImage.height;

    const offscreen = new OffscreenCanvas(
      uploadedImageCropped.width * scaleX,
      uploadedImageCropped.height * scaleY,
    );
    const ctx = offscreen.getContext('2d');
    if (!ctx) {
      throw new Error('No 2d context');
    }

    ctx.drawImage(
      uploadedImageCroppedCanvas,
      0,
      0,
      uploadedImageCroppedCanvas.width,
      uploadedImageCroppedCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    );

    return offscreen.convertToBlob({
      type: 'image/png',
      quality: 1,
    });
  };


  function onImageLoad(event) {
    const { width, height } = event.currentTarget;
    setCrop(centerAspectCrop(width, height, aspectRatio));
  }

  useDebounceEffect(
    async () => {
      if (
        uploadedImageCropped?.width &&
        uploadedImageCropped?.height &&
        uploadedImagePreviewRef.current &&
        uploadedImageCroppedPreviewCanvasRef.current
      ) {
        canvasPreview(
          uploadedImagePreviewRef.current,
          uploadedImageCroppedPreviewCanvasRef.current,
          uploadedImageCropped,
          1,
          0,
        );
      }
    },
    100,
    [uploadedImageCropped],
  );

  const handleFileInputImageChange = (event) => {
    const filePayload = event.target.files[0];

    if (!allowedImageTypes.includes(filePayload.type)) {
      throw new InvalidImageException('');
    }

    setUploadedImagePreviewUrl(URL.createObjectURL(filePayload));
  };

  const reactCropProps = {
    crop,
    onChange: (_, percentCrop) => setCrop(percentCrop),
    onComplete: (c) => {
      console.log('@uploadedImageCropped', uploadedImageCropped);
      return setUploadedImageCropped(c)
    },
    aspect: aspectRatio
  };

  const reactCropImgProps = {
    ref: uploadedImagePreviewRef,
    src: uploadedImagePreviewUrl,
    style: { transform: `scale(1) rotate(0deg)` },
    onLoad: onImageLoad,
  };

  return {
    handleFileInputImageChange,
    getUploadedImagePreviewAsBlob,
    uploadedImagePreviewUrl,
    uploadedImageCropped,
    uploadedImageCroppedPreviewCanvasRef,
    reactCropProps,
    reactCropImgProps,
  };
}
