import { REGISTER_USER_SUCCESS } from 'app/users/reducers/current';
import { post } from 'core/helpers/fetch';
import {wsSend} from "core/websocket/redux/actions";

export default function registerUser(user) {
  return async dispatch => {
    const createdUser = await dispatch(post('/users', user));
    dispatch({
      type: REGISTER_USER_SUCCESS,
      payload: { user: createdUser },
    });
    dispatch(wsSend('register', { userId: createdUser.id }));
    return createdUser;
  };
}
