import { putFormData } from 'core/helpers/fetch';
import { UPDATE_CARD_SUCCESS, UPDATE_CARD_ERROR } from 'app/games/whoisit/reducers/cards';

function updateCard(id, name, image) {
  return async dispatch => {
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('image', image);
      const updatedCard = await dispatch(putFormData(`/admin/whoisit/cards/${id}`, formData));
      dispatch({ type: UPDATE_CARD_SUCCESS, payload: { card: updatedCard }})
    } catch (exception) {
      dispatch({ type: UPDATE_CARD_ERROR, payload: { exception }});
      throw exception;
    }
  };
}

export default updateCard;
