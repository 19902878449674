import React, { useRef } from 'react';
import 'app/common/input/file.css';

const InputFile = ({ name, handleFile, className, children }) => {
  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <button className={className} onClick={handleClick}>
        {children}
      </button>
      <input
        name={name}
        type="file"
        onChange={handleFile}
        ref={hiddenFileInput}
        style={{display: 'none'}}
      />
    </>
  );
};

export default InputFile;
