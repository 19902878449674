import getInstance from "app/games/taboo/selectors/getInstance";
import {
  TABOO_FILTER_TYPE_CATEGORY,
  TABOO_FILTER_TYPE_DIFFICULTY
} from "app/games/taboo/reducers/currentUserFilterPerInstance";

export default function getInstanceRemainingCardsCounty(state, instanceId, filter) {
  const instance = getInstance(state, instanceId);

  switch (filter.type) {
    case TABOO_FILTER_TYPE_DIFFICULTY:
      return instance?.remainingCardsCountByDifficulty?.[filter.difficultyId] ?? 0;
    case TABOO_FILTER_TYPE_CATEGORY:
      return instance?.remainingCardsCountByCategory?.[filter.categoryId] ?? 0;
    default:
      return 0;
  }
}
