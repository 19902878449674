import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import createUser from 'app/users/actions/createUser'
import Form from 'app/users/login/form';
import Field from 'app/users/login/form/field';
import Errors from 'app/users/login/form/errors';
import Welcome from 'app/users/login/form/welcome';
import BottomLinks from 'app/users/login/form/bottomLinks';
import 'app/users/login/index.css';
import {PAGE_LOGIN_BY_NAME} from "app/users/login/loginByName";
import {PAGE_LOGIN_BY_CREDENTIALS} from "app/users/login/loginByCredentials";
import Submit from "app/users/login/form/submit";

export const PAGE_CREATE_CREDENTIALS = 'create';

const CreateCredentials = ({ changePage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ error, setError ] = useState(null);
  const [ inputName, setInputName ] = useState('');
  const [ inputEmail, setInputEmail ] = useState('');
  const [ inputPassword, setInputPassword ] = useState('');

  const submitRegister = async () => {
    try {
      await dispatch(createUser({ name: inputName, email: inputEmail, password: inputPassword }));
    } catch (exception) {
      setError(exception.message);
    }
  };

  const handleChangeName = event => {
    setInputName(event.target.value);
    setError(null);
  };

  const handleChangeEmail = event => {
    setInputEmail(event.target.value);
    setError(null);
  };

  const handleChangePassword = event => {
    setInputPassword(event.target.value);
    setError(null);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      submitRegister();
    }
  };

  const goToLoginByName = event => {
    event.preventDefault();
    changePage(PAGE_LOGIN_BY_NAME);
  };

  const goToLoginByCredentials = event => {
    event.preventDefault();
    changePage(PAGE_LOGIN_BY_CREDENTIALS);
  };

  return (
    <div className="CreateCredentials panel padded">
      <Welcome>{t('login.welcomeCreateCredentials')}</Welcome>
      <Form inline={false}>
        <Field label={t('login.createCredentials.name.label')}>
          <input
            className="LoginNameInput"
            type="text"
            value={inputName}
            onChange={handleChangeName}
            onKeyPress={handleKeyPress}
          />
        </Field>
        <Field label={t('login.createCredentials.email.label')}>
          <input
            className="LoginEmailInput"
            type="text"
            value={inputEmail}
            onChange={handleChangeEmail}
            onKeyPress={handleKeyPress}
          />
        </Field>
        <Field label={t('login.createCredentials.password.label')}>
          <input
            className="LoginPasswordInput"
            type="password"
            value={inputPassword}
            onChange={handleChangePassword}
            onKeyPress={handleKeyPress}
          />
        </Field>
        <Submit onClick={submitRegister}>{t('login.createCredentials.submit')}</Submit>
      </Form>
      {error && <Errors>{error}</Errors>}
      <BottomLinks>
        <div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="" onClick={goToLoginByCredentials}>{t('login.createCredentials.backToLoginByCredentials')}</a>
        </div>
        <div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="" onClick={goToLoginByName}>{t('login.createCredentials.backToLoginByName')}</a>
        </div>
      </BottomLinks>
    </div>
  );
};

export default CreateCredentials;
