import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTable, useSortBy, useGlobalFilter, useRowState } from 'react-table';
import { useTranslation } from "react-i18next";
import getCards from 'app/games/taboo/selectors/getCards';
import getDifficulties from 'app/games/taboo/selectors/getDifficulties';
import getCategories from 'app/games/taboo/selectors/getCategories';
import Search from 'app/admin/taboo/cards/table/search';
import DeleteButton from 'app/admin/taboo/cards/table/deleteButton';
import EditButton from 'app/admin/taboo/cards/table/editButton';
import 'app/admin/taboo/cards/index.css';

function normalizeString(string) {
  return string.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase();
}

function getDifficultyOrCategory(difficulty, category, difficulties, categories, t) {
  if (difficulty) {
    const difficultySlug = difficulties.find(({ id }) => id === difficulty)?.slug;
    if (difficultySlug) {
      return t(`admin.taboo.difficulty.${difficultySlug}`);
    }
  } else if (category) {
    const categoryName = categories.find(({ id }) => id === category)?.name;
    if (categoryName) {
      return t(`admin.taboo.category.${categoryName}`);
    }
  }
  return '';
}

const AdminTabooCards = () => {
  const { t } = useTranslation();
  const cards = useSelector(getCards);
  const difficulties = useSelector(getDifficulties);
  const categories = useSelector(getCategories);

  const data = useMemo(
    () => cards.map(({ id, word, forbidden, difficulty, category }) => {
      return ({
        id,
        word,
        forbidden: forbidden.join(', '),
        difficultyOrCategory: getDifficultyOrCategory(difficulty, category, difficulties, categories, t),
      })
    }),
    [cards, difficulties, categories, t]
  );

  const columns = useMemo(
    () => {
      return [
        {
          Header: t('admin.taboo.cards.column.id'),
          accessor: ({ id }) => id,
        },
        {
          Header: t('admin.taboo.cards.column.word'),
          accessor: 'word',
        },
        {
          Header: t('admin.taboo.cards.column.forbidden'),
          accessor: 'forbidden',
        },
        {
          Header: t('admin.taboo.cards.column.difficultyOrCategory'),
          accessor: 'difficultyOrCategory',
        },
        {
          Header: t('admin.taboo.cards.column.actions'),
          accessor: ({ id }) => <><EditButton id={id} /><DeleteButton id={id} /></>,
          disableSortBy: true,
        },
      ]
    },
    [t]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'ID',
            asc: true,
          }
        ]
      },
      autoResetSortBy: false,
      autoResetRowState: false,
      defaultCanSort: true,
      disableSortRemove: true,
      globalFilter: (rows, columns, searchText) => {
        const tokens = normalizeString(searchText)
          .replace(/[,:;/\\[\]()!?.]/g, ' ') // Remove punctuation
          .match(/(\b[^\s]+\b)/g); // Split words

        if (tokens == null) {
          return [];
        }

        const searchFields = ['ID', 'word', 'forbidden', 'difficulty'];
        return rows.filter(({ values }) => {
          return tokens.filter(token => searchFields.some(field => {
            return normalizeString(values[field]).includes(token);
          })).length === tokens.length;
        });
      },
    },
    useGlobalFilter,
    useSortBy,
    useRowState,
  );

  return (
    <div className="AdminTabooCards">
      <div className="AdminTabooCardsSearch">
        <Search
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                <span className="SortBy" {...column.getSortByToggleProps()}>
                  {column.render('Header')}
                  {column.isSorted
                    ? column.isSortedDesc
                      ? <span className="SortArrow"> ▾</span>
                      : <span className="SortArrow"> ▴</span>
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
    </div>
  );
};

export default AdminTabooCards;
