import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useParamSelector from 'core/redux/hooks/useParamSelector';
import useFetchInstance from 'app/games/whoisit/hooks/useFetchInstance';
import useFetchCards from 'app/games/whoisit/hooks/useFetchCards';
import getInstance from 'app/games/whoisit/selectors/getInstance';
import Desktop from 'app/desktop';
import LoadingScreen from 'app/loadingScreen';
import Loading from 'app/games/whoisit/loading';
import Game from 'app/games/whoisit/game';
import GameTitle from "app/games/whoisit/gameTitle";
import 'app/games/whoisit/index.css';

function WhoisitContent() {
  const { t } = useTranslation();
  const { instanceId } = useParams();
  const instance = useParamSelector(getInstance, instanceId);

  if (!instance) {
    return (
      <div>{t('games.whoisit.instance.notFound')}</div>
    );
  }

  return (
    <Game instance={instance}/>
  );
}

function Whoisit() {
  const { t } = useTranslation();
  const { instanceId } = useParams();
  const { isRunning: isLoadingInstance } = useFetchInstance(instanceId);
  const isLoadingCards = useFetchCards();
  const isLoading = isLoadingInstance || isLoadingCards;

  return (
    <Desktop title={t('games.title')}>
      <div className="Whoisit">
        <GameTitle />
        <LoadingScreen isLoading={isLoading} fallback={<div className="WhoisitLoading"><Loading /></div>}>
          <WhoisitContent />
        </LoadingScreen>
      </div>
    </Desktop>
  );
}

export default Whoisit;
