import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactModal from 'react-modal'
import getModal from 'app/modal/selectors/getModal';
import closeModal from 'app/modal/actions/close';
import 'app/modal/index.css';

const Modal = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getModal);
  const ContentComponent = modal.component;

  if (!modal.open || ContentComponent == null) {
    return null;
  }

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <div>
      <ReactModal
        isOpen={true}
        onRequestClose={handleClose}
        contentLabel="Example Modal"
        ariaHideApp={false}
        className="ModalRoot"
        overlayClassName="ModalRootOverlay"
        shouldCloseOnOverlayClick={false}
      >
        <ContentComponent {...modal.props} closeModal={handleClose} />
      </ReactModal>
    </div>
  );
};

export default Modal;
