import React from 'react';
import 'app/users/login/form/bottomLinks.css';

const BottomLinks = ({ children }) => (
  <div className="LoginBottomLinks">
    {children}
  </div>
);

export default BottomLinks;
