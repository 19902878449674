import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {PATH_GAMES, PATH_GAMES_WHOISIT_INSTANCE} from "app/routes";
import generatePathname from "core/helpers/generatePathname";
import createInstance from 'app/games/whoisit/actions/createInstance';
import GameTitle from 'app/games/whoisit/gameTitle';
import Desktop from 'app/desktop';
import 'app/games/whoisit/createInstance.css';

export default function CreateInstance() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const createdInstance = await dispatch(createInstance());
    history.push(generatePathname(PATH_GAMES_WHOISIT_INSTANCE, { instanceId: createdInstance.id }));
  };

  return (
    <Desktop title={t('games.title')}>
      <div className="WhoisitCreateInstance">
        <GameTitle />
        <div className="WhoisitCreateInstanceButtons panel padded">
          <button className="WhoisitCreateInstanceSubmit button primary xlarge" onClick={handleSubmit}>
            {t('games.whoisit.newInstance.submit')}
          </button>
          <Link to={PATH_GAMES} className="WhoisitCreateInstanceBack BackToGames button secondary">
            {t('games.backToGames')}
          </Link>
        </div>
      </div>
    </Desktop>
  );
}
