import React, { useState } from 'react'
import {useDispatch, useSelector} from 'react-redux';
import notifySuccess from 'app/notifications/notifySuccess';
import notifyError from 'app/notifications/notifyError';
import deleteCard from 'app/admin/whoisit/actions/deleteCard';
import getCard from 'app/games/whoisit/selectors/getCard';
import ModalContent from 'app/modal/content';
import 'app/admin/whoisit/cards/modal/delete.css';
import {useTranslation} from "react-i18next";

function DeleteCard({ id, closeModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const card = useSelector(state => getCard(state, id));

  const handleConfirm = async () => {
    setLoading(true);

    try {
      await dispatch(deleteCard(id));
      notifySuccess(t('admin.whoisit.card.deleteForm.success'));
      setLoading(false);
      closeModal();
    } catch (exception) {
      setLoading(false);
      notifyError(exception);
    }
  };

  return (
    <ModalContent title={t('admin.whoisit.card.deleteForm.title')} closeModal={closeModal} loading={isLoading}>
      <div className="WhoisitDeleteCardConfirmMessage">
        {t('admin.whoisit.card.deleteForm.warning', { name: card ? card.name : null })}
      </div>
      <div className="WhoisitDeleteCardConfirmButtons">
        <button className="button medium primary" onClick={handleConfirm}>
          {t('admin.whoisit.card.deleteForm.confirm')}
        </button>
        <button className="button medium secondary" onClick={closeModal}>
          {t('admin.whoisit.card.deleteForm.cancel')}
        </button>
      </div>
    </ModalContent>
  );
}

export default DeleteCard;
