import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'
import config from "../../config";

export default function bootstrapBugsnag() {
  Bugsnag.start({
    apiKey: config.bugsnag.apiKey,
    plugins: [new BugsnagPluginReact()]
  });
  BugsnagPerformance.start({ apiKey: config.bugsnag.apiKey });
}
