import React from 'react';
import classnames from 'classnames';
import 'app/users/login/index.css';

const Form = ({ inline = false, children }) => (
  <div className={classnames("LoginForm", { LoginFormVertical: !inline, LoginFormHorizontal: inline })}>
    {children}
  </div>
);

export default Form;
