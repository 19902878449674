import { REGISTER_USER_SUCCESS } from 'app/users/reducers/current';
import { post } from 'core/helpers/fetch';
import { wsSend } from 'core/websocket/redux/actions';

function tryWebsocketRegister(user, retries = 3) {
  return dispatch => {
    try {
      dispatch(wsSend('register', { userId: user.id }));
    } catch (exception) {
      if (retries > 0) {
        tryWebsocketRegister(retries - 1);
      }
    }
  }
}

export default function registerUser(payload) {
  return async dispatch => {
    const user = await dispatch(post('/auth', payload));
    dispatch({
      type: REGISTER_USER_SUCCESS,
      payload: { user },
    });
    dispatch(tryWebsocketRegister(user));
    return user;
  };
}
