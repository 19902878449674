import { useTranslation } from 'react-i18next';
import 'app/games/whoisit/gameTitle.css';

const GameTitle = () => {
  const { t } = useTranslation();

  return (
    <div className="WhoisitGameTitle">
      <div className="WhoisitGameTitleText">
        {t('games.whoisit.title')}
      </div>
    </div>
  );
};

export default GameTitle;
