export const WEBSOCKET_CONNECT = 'websocket.connect';
export const WEBSOCKET_CONNECTING = 'websocket.connecting';
export const WEBSOCKET_CONNECTED = 'websocket.connected';
export const WEBSOCKET_DISCONNECT = 'websocket.disconnect';
export const WEBSOCKET_DISCONNECTED = 'websocket.disconnected';
export const WEBSOCKET_SEND = 'websocket.send';
export const WEBSOCKET_SERVER_PING = 'websocket.ping';

export const wsConnect = () => ({ type: WEBSOCKET_CONNECT });
export const wsConnecting = () => ({ type: WEBSOCKET_CONNECTING });
export const wsConnected = () => ({ type: WEBSOCKET_CONNECTED });
export const wsDisconnect = () => ({ type: WEBSOCKET_DISCONNECT });
export const wsDisconnected = () => ({ type: WEBSOCKET_DISCONNECTED });
export const wsSend = (command, payload) => ({ type: WEBSOCKET_SEND, payload: { command, payload }});
export const wsServerPing = () => ({ type: WEBSOCKET_SERVER_PING });
