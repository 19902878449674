import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useParamSelector from 'core/redux/hooks/useParamSelector';
import pickInstanceCard from 'app/games/whoisit/actions/pickInstanceCard';
import getInstanceRemainingCardsCount from 'app/games/whoisit/selectors/getInstanceRemainingCardsCount';
import getCurrentUserCard from 'app/games/whoisit/selectors/getCurrentUserCard';
import isPickingCardByInstance from 'app/games/whoisit/selectors/isPickingCardByInstance';
import { ReactComponent as IconCards } from 'assets/icon/cards.svg';
import WhoisitCard from 'app/games/whoisit/card';
import 'app/games/whoisit/game.css';
import notifyError from "../../notifications/notifyError";

function Game({ instance }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const remainingCardsCount = useParamSelector(getInstanceRemainingCardsCount, instance.id);
  const currentCard = useParamSelector(getCurrentUserCard, instance);
  const isPickingCard = useParamSelector(isPickingCardByInstance, instance);

  const hasRemainingCards = remainingCardsCount !== 0;

  const handlePickCard = async () => {
    try {
      await dispatch(pickInstanceCard(instance.id));
    } catch (exception) {
      notifyError(exception);
    }
  };

  return (
    <div className="WhoisitBoard">
      <div className="WhoisitControls">
        <div className="WhoisitPickCard">
          <button className="WhoisitPickCardButton button xlarge primary" onClick={handlePickCard} disabled={isPickingCard || !hasRemainingCards}>
            <IconCards className="WhoisitPickCardButtonIcon" />
            {t('games.whoisit.pickCard')}
          </button>
          <div className="WhoisitAvailableCardsCount">
            {remainingCardsCount > 0
              ? t('games.whoisit.cardsToPickCount', { count: remainingCardsCount })
              : t('games.whoisit.cardsToPickCount_zero')
            }
          </div>
        </div>
      </div>
      <div className="WhoisitPickedCard">
        {currentCard && <WhoisitCard card={isPickingCard ? null : currentCard} />}
      </div>
    </div>
  );
}

export default Game;
