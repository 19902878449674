import { get } from 'core/helpers/fetch';
import {
  FETCH_DIFFICULTIES_START,
  FETCH_DIFFICULTIES_SUCCESS,
  FETCH_DIFFICULTIES_ERROR,
} from 'app/games/taboo/reducers/difficulties';

function fetchDifficulties() {
  return async dispatch => {
    dispatch({ type: FETCH_DIFFICULTIES_START });
    try {
      const difficulties = await dispatch(get('/games/taboo/difficulties'));
      dispatch({ type: FETCH_DIFFICULTIES_SUCCESS, payload: { difficulties } });
      return difficulties;
    } catch (exception) {
      dispatch({ type: FETCH_DIFFICULTIES_ERROR, payload: { exception } });
      throw exception;
    }
  };
}

export default fetchDifficulties;
