import React from 'react'
import Spinner from 'app/spinner';
import 'app/modal/content.css';
import {useTranslation} from "react-i18next";

const ModalContent = ({ title, children, closeModal, loading, contentClassName }) => {
  const { t } = useTranslation();

  const contentClassNames = `ModalContentBody${contentClassName ? ` ${contentClassName}` : ''}`;

  return (
    <div className="ModalContent">
      <header className="ModalContentHeader">
        <section className="ModalContentHeaderLeft">
          <div className="ModalContentHeaderTitle">
            {title}
          </div>
        </section>
        <section className="ModalContentHeaderRight">
          <button type="button" className="ModalContentHeaderClose" onClick={closeModal} title={t('modal.close.tooltip')}>X</button>
        </section>
      </header>
      <div className={contentClassNames}>
        {children}
      </div>
      {loading && <div className="ModalContentLoading"><Spinner color="#333333"/></div>}
    </div>
  )
};

export default ModalContent;
