import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import notifyError from "app/notifications/notifyError";
import InputFile from 'app/common/input/file';
import FormInputImageCrop from "app/admin/whoisit/cards/modal/formInputImageCrop";
import useCardFormImageUploadWithCrop, { InvalidImageException } from "app/admin/whoisit/cards/modal/useCardFormImageUploadWithCrop";
import 'app/admin/whoisit/cards/modal/form.css';
import 'react-image-crop/dist/ReactCrop.css'

function generateNewCardValues() {
  return {
    name: '',
    image: null,
  };
}

function generateCardValues(card) {
  return {
    name: card.name,
    image: null
  };
}

function generateNameFromFilename(filename) {
  return filename
    .replace(/\.[^\\.]+$/, '')
    .replace(/[#*'"<>^]+/g, '')
    .replace(/[_/\\.\s]+/g, ' ');
}

const cropAspectRatio = 318 / 338;
const allowedImageTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/webp'];

function CardForm({ submitLabel, onSubmit, card = null }) {
  const {t} = useTranslation();
  const cardValues = card ? generateCardValues(card) : generateNewCardValues();
  const [values, setValues] = useState(cardValues);

  const {
    handleFileInputImageChange,
    getUploadedImagePreviewAsBlob,
    uploadedImagePreviewUrl,
    uploadedImageCropped,
    uploadedImageCroppedPreviewCanvasRef,
    reactCropProps,
    reactCropImgProps,
  } = useCardFormImageUploadWithCrop({
    cropAspectRatio,
    allowedImageTypes
  });

  const handleInputChange = (event) => setValues({
    ...values,
    [event.target.name]: event.target.value
  });

  const handleSubmit = async () => {
    try {
      const image = await getUploadedImagePreviewAsBlob();
      onSubmit(values.name, image);
    } catch (exception) {
      notifyError(exception);
    }
  };

  const handleFileInputChange = (event) => {
    try {

      const filePayload = event.target.files[0];

      console.log('@filePayload');

      if (values.name === '') {
        setValues({ ...values, name: generateNameFromFilename(filePayload.name) })
      }

      handleFileInputImageChange(event);
    } catch (exception) {
      if (exception instanceof InvalidImageException) {
        notifyError(t('admin.whoisit.card.form.invalidImageFile'));
      } else {
        notifyError(exception);
      }
    }
  };

  return (
    <div className="CardForm">
      <div className="CardFormName CardFormInputRow">
        <label htmlFor="name">{t('admin.whoisit.card.form.name.label')}</label>
        <input name="name" value={values.name} type="text" onChange={handleInputChange} />
      </div>
      <div className="CardFormImage CardFormInputRow">
        <label htmlFor="image">{t('admin.whoisit.card.form.imageFile.label')}</label>
        <div className="CardFormImageInput">
          <InputFile name="image" className="button secondary medium" handleFile={handleFileInputChange}>
            {t('admin.whoisit.card.form.imageFile.button')}
          </InputFile>
        </div>
          {uploadedImagePreviewUrl ? (
            <div className="CardFormImageCrop">
              <FormInputImageCrop
                uploadedImagePreviewUrl={uploadedImagePreviewUrl}
                uploadedImageCropped={uploadedImageCropped}
                uploadedImageCroppedPreviewCanvasRef={uploadedImageCroppedPreviewCanvasRef}
                reactCropProps={reactCropProps}
                reactCropImgProps={reactCropImgProps}
              />
            </div>
          ) : (
            <div className="CardFormImagePreview">
              {card?.image && <img src={card?.image} alt="" />}
            </div>
          )}
      </div>
      <div className="CardFormSave">
        <button className="CardFormSaveButton button primary" onClick={handleSubmit}>
          {submitLabel}
        </button>
      </div>
    </div>
  );
}

export default CardForm;
