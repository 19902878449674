import { useTranslation } from 'react-i18next';
import 'app/games/hangedman/gameTitle.css';

const GameTitle = () => {
  const { t } = useTranslation();

  return (
    <div className="HangedmanGameTitle">
      <div className="HangedmanGameTitleText">
        {t('games.hangedman.title')}
      </div>
    </div>
  );
};

export default GameTitle;
