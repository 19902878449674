import React, {useRef, useState} from 'react';
import i18next from 'i18next';
import classnames from 'classnames';
import useOutboundClick from 'core/redux/hooks/useOutboundClick';
import getCurrentLanguageCode from 'core/helpers/getCurrentLanguageCode';
import {ReactComponent as IconFlagFr} from 'assets/icon/flag-fr.svg';
import {ReactComponent as IconFlagDe} from 'assets/icon/flag-de.svg';
import 'app/languageSwitch.css';

const LanguageSwitch = () => {
  const [language, setLanguage] = useState(getCurrentLanguageCode());
  const [opened, setOpened] = useState(false);

  const wrapperRef = useRef(null);
  useOutboundClick(wrapperRef, () => {setOpened(false)});

  const flags = { fr: IconFlagFr, de: IconFlagDe };
  const FlagIcon = flags[language];

  const handleToggle = () => setOpened(!opened);
  const handleChangeLanguage = async code => {
    setOpened(false);
    await i18next.changeLanguage(code);
    setLanguage(code);
  };

  return (
    <div className="LanguageSwitch" ref={wrapperRef}>
      {FlagIcon && <FlagIcon className="LanguageSwitchCurrentIcon" onClick={handleToggle}/>}
      {opened &&
        <div className="LanguageSwitchMenu">
          {Object.entries(flags).map(([code, Component]) => {
            const isCurrentLanguage = code === language;
            return (
              <div
                key={code}
                className={classnames('LanguageSwitchMenuEntry', { current: isCurrentLanguage})}
                onClick={() => !isCurrentLanguage && handleChangeLanguage(code)}
              >
                <Component className="LanguageSwitchMenuEntryIcon"/>
              </div>
            )
          })}
        </div>
      }
    </div>
  );
};

export default LanguageSwitch;
