import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import websocketMiddleware from 'core/websocket/redux/middleware';
import combineReducersTree from 'core/redux/combineReducersTree';

export default function createAppStore(reducersTree, websocketReducer) {
  const reducers = combineReducersTree(reducersTree);

  const persistedReducer = persistReducer({
    key: 'root',
    storage,
    whitelist: ['authToken', 'games'],
    version: 1.2,
  }, reducers);
  const store = createStore(persistedReducer, applyMiddleware(thunkMiddleware, websocketMiddleware));

  const persistor = persistStore(store);

  return { store, persistor };
}
