import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {
  Switch,
  Route,
} from 'react-router-dom';
import Desktop from 'app/desktop';
import TabooAdmin from 'app/admin/taboo';
import WhoisitAdmin from "app/admin/whoisit";
import 'app/admin/index.css';

function Admin({ match: { url } }) {
  const { t } = useTranslation();

  return (
    <Desktop title={t('games.title')}>
      <div className="Admin">
        <Switch>
          <Route path={`${url}/taboo`}>
            <TabooAdmin />
          </Route>
          <Route path={`${url}/whoisit`}>
            <WhoisitAdmin />
          </Route>
          <Route path={url}>
            <Link to={`${url}/taboo`} className="AdminLink tile large primary onPrimary">
              {t('admin.taboo.linkTitle')}
            </Link>
            <Link to={`${url}/whoisit`} className="AdminLink tile large primary onPrimary">
              {t('admin.whoisit.linkTitle')}
            </Link>
          </Route>
        </Switch>
      </div>
    </Desktop>
  );
}

export default Admin;
