import { REGISTER_CURRENT_USER_ERROR } from 'app/users/reducers/current';
import registerUser from 'app/users/actions/registerUser';
import getAuthToken from "app/users/selectors/getAuthToken";

export default function registerUserByToken() {
  return async (dispatch, getState) => {
    const token = getAuthToken(getState());
    if (token != null) {
      try {
        const user = await dispatch(registerUser({ token }));
        return !!user;
      } catch (exception) {
        console.warn(exception);
        dispatch({ type: REGISTER_CURRENT_USER_ERROR });
      }
    }
  };
}
