import modalReducer from 'app/modal/reducers';
import isAuthenticatedReducer from 'app/users/reducers/isAuthenticated';
import authTokenReducer from 'app/users/reducers/authToken';
import currentUserReducer from 'app/users/reducers/current';
import usersReducer from 'app/users/reducers/list';
import tabooInstancesReducer from 'app/games/taboo/reducers/instances';
import tabooCardsReducer from 'app/games/taboo/reducers/cards';
import tabooDifficultiesReducer from 'app/games/taboo/reducers/difficulties';
import tabooCategoriesReducer from 'app/games/taboo/reducers/categories';
import tabooCurrentUserCardReducer from 'app/games/taboo/reducers/currentUserCard';
import tabooCurrentUserFilterPerInstance from 'app/games/taboo/reducers/currentUserFilterPerInstance';
import whoisitInstancesReducer from 'app/games/whoisit/reducers/instances';
import whoisitCardsReducer from 'app/games/whoisit/reducers/cards';
import whoisitCurrentUserCardReducer from 'app/games/whoisit/reducers/currentUserCard';
import hangedmanInstancesReducer from 'app/games/hangedman/reducers/instances';

export default {
  modal: modalReducer,
  authToken: authTokenReducer,
  isAuthenticated: isAuthenticatedReducer,
  currentUser: currentUserReducer,
  users: usersReducer,
  games: {
    taboo: {
      instances: tabooInstancesReducer,
      cards: tabooCardsReducer,
      difficulties: tabooDifficultiesReducer,
      categories: tabooCategoriesReducer,
      currentUserCard: tabooCurrentUserCardReducer,
      currentUserFilterPerInstance: tabooCurrentUserFilterPerInstance,
    },
    whoisit: {
      instances: whoisitInstancesReducer,
      cards: whoisitCardsReducer,
      currentUserCard: whoisitCurrentUserCardReducer,
    },
    hangedman: {
      instances: hangedmanInstancesReducer,
    }
  }
};
