import React, { useEffect, useState, useRef } from 'react';

const SCALE = 1;
const WIDTH = 320;
const HEIGHT = 368;
const LINE_WIDTH = 7;
const LINE_WIDTH_BIG = 10;
const LINE_WIDTH_HUGE = 15;

// Theme "white"
const BLACK = '#ffffff';
const WHITE = '#ffffff';
const WOOD = '#ffffff';
const ROPE = '#ffffff';
const SKIN = '#ffffff';
const CLOTH = '#ffffff';
const JEAN = '#ffffff';
const EYE = '#000000';
const MOUTH = '#000000';

// Theme "real"
// const BLACK = '#000000';
// const WHITE = '#ffffff';
// const WOOD = '#683e14';
// const ROPE = '#b38315';
// const SKIN = '#edc1cf';
// const CLOTH = '#444444';
// const JEAN = '#348bce';
// const EYE = '#000000';
// const MOUTH = '#000000';

export default function useGameCanvas(instance) {
  const canvasRef = useRef(null);
  const drawStep = [...instance.proposedLetters].filter(letter => !instance.word.includes(letter)).length;

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    if (!context) {
      return;
    }

    const drawLine = (fromX, fromY, toX, toY, color = BLACK, width = LINE_WIDTH) => {
      context.beginPath();
      context.moveTo(fromX, fromY);
      context.lineTo(toX, toY);
      context.lineWidth = width;
      context.strokeStyle = color;
      context.stroke();
    };

    const drawPlainCircle = (fromX, fromY, radius, color = BLACK, startAngle = 0, endAngle = 2 * Math.PI) => {
      context.beginPath();

      // Quick fix because of the padding on the canvas that compress the image vertically
      // context.ellipse(fromX, fromY, radius, radius * 1.2, 0, startAngle, endAngle);
      context.arc(fromX, fromY, radius, startAngle, endAngle);

      context.fillStyle = color;
      context.fill();
    };

    const drawCircle = (fromX, fromY, radius, color = BLACK, startAngle = 0, endAngle = 2 * Math.PI) => {
      context.beginPath();
      context.arc(fromX, fromY, radius, startAngle, endAngle);
      context.strokeStyle = color;
      context.stroke();
    };

    const x = (offset) => offset >= 0 ? offset : context.canvas.width + offset;
    const y = (offset) => offset >= 0 ? offset : context.canvas.height + offset;

    const drawSteps = [
      // Part 1 (base)
      () => drawLine(
        x(-20), y(-20),
        x(-160), y(-20),
        BLACK,
        LINE_WIDTH_HUGE,
      ),

      // Part 2 (poteau vertical)
      () => drawLine(
        x(-70), y(-25),
        x(-70), y(20),
        WOOD,
        LINE_WIDTH_HUGE,
      ),

      // Part 3 (poteau horizonal)
      () => drawLine(
        x(-65), y(28),
        x(-200), y(28),
        WOOD,
        LINE_WIDTH_HUGE,
      ),

      // Part 4 (poteau soutien diagonal)
      () => drawLine(
        x(-70), y(75),
        x(-120), y(25),
        WOOD,
        LINE_WIDTH_HUGE,
      ),

      // Part 5 (corde)
      () => drawLine(
        x(-180), y(20),
        x(-180), y(74),
        ROPE,
      ),

      // Part 6 tete
      () => drawPlainCircle(x(-180), y(95), 20, SKIN),

      // Part 7 (corps)
      () => drawLine(
        x(-180), y(115),
        x(-180), y(200),
        CLOTH,
      ),

      // Part 8 (bras droit)
      () => drawLine(
        x(-180), y(140),
        x(-155), y(175),
        CLOTH,
      ),

      // Part 9 (main droite)
      () => drawLine(
        x(-159), y(175),
        x(-150), y(168),
        SKIN,
      ),

      // Part 10 (bras gauche)
      () => drawLine(
        x(-180), y(140),
        x(-205), y(175),
        CLOTH,
      ),

      // Part 11 (main gauche)
      () => drawLine(
        x(-201), y(175),
        x(-210), y(168),
        SKIN,
      ),

      // Part 12 (jambe droite)
      () => drawLine(
        x(-180), y(200),
        x(-160), y(240),
        JEAN,
      ),

      // Part 13 (pied droit)
      () => drawLine(
        x(-165), y(238),
        x(-151), y(232),
        SKIN,
      ),

      // Part 14 (jambe gauche)
      () => drawLine(
        x(-180), y(200),
        x(-200), y(240),
        JEAN,
      ),

      // Part 15 (pied gauche)
      () => drawLine(
        x(-195), y(238),
        x(-210), y(232),
        SKIN,
      ),

      // Part 16 (oeil droit croix 1)
      () => drawLine(
        x(-177), y(87),
        x(-167), y(97),
        EYE,
        3
      ),

      // Part 17 (oeil droit croix 2)
      () => drawLine(
        x(-167), y(87),
        x(-177), y(97),
        EYE,
        3
      ),

      // Part 18 (oeil gauche croix 1)
      () => drawLine(
        x(-192), y(87),
        x(-182), y(97),
        EYE,
        3
      ),

      // Part 19 (oeil gauche croix 2)
      () => drawLine(
        x(-182), y(87),
        x(-192), y(97),
        EYE,
        3
      ),

      // Part 20 bouche
      () => drawCircle(x(-180), y(110), 6, MOUTH, 3.5, Math.PI * -0.1),
    ];

    canvas.height = HEIGHT * SCALE;
    canvas.width = WIDTH * SCALE;
    context.scale(SCALE, SCALE);

    // Fill background with white
    context.fillStyle = '#ffffff';
    // context.fillRect(0, 0, context.canvas.width, context.canvas.height);
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    context.beginPath();

    // Now write in black
    context.fillStyle = '#000000';

    // Draw steps
    for (let i = 0; i < drawStep; i++) {
      if (drawSteps[i]) {
        drawSteps[i]();
      }
    }

    // window.drawLine = drawLine;
    // window.drawPlainCircle = drawLine;
    // window.x = x;
    // window.y = y;
  }, [drawStep]);

  const canvasProps = {
    ref: canvasRef,
    style: {
      width: WIDTH * SCALE,
      height: HEIGHT * SCALE,
    },
  };

  return { canvasProps };
}
