import getCardsByDifficulty from 'app/games/taboo/selectors/getCardsByDifficulty';
import getCardsByCategory from 'app/games/taboo/selectors/getCardsByCategory';
import {TABOO_FILTER_TYPE_DIFFICULTY} from "app/games/taboo/reducers/currentUserFilterPerInstance";

export default function getCurrentUserCard(state, instanceId, filter) {
  const currentCardId = filter.type === TABOO_FILTER_TYPE_DIFFICULTY
    ? state.games.taboo.currentUserCard[instanceId]?.byDifficulty?.[filter.difficultyId]
    : state.games.taboo.currentUserCard[instanceId]?.byCategory?.[filter.categoryId];

  if (currentCardId == null) {
    return null;
  }

  const cardsSet = filter.type === TABOO_FILTER_TYPE_DIFFICULTY
    ? getCardsByDifficulty(state, filter.difficultyId)
    : getCardsByCategory(state, filter.categoryId);

  return cardsSet.find(card => card.id === currentCardId);
}
