import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import useFetchCards from 'app/games/taboo/hooks/useFetchCards';
import useFetchDifficulties from 'app/games/taboo/hooks/useFetchDifficulties';
import openModal from 'app/modal/actions/open';
import { FullPageSpinner } from 'app/spinner';
import AdminTabooCards from 'app/admin/taboo/cards'
import CreateCard from 'app/admin/taboo/cards/modal/create';
import { ReactComponent as IconPlus } from 'assets/icon/plus.svg';
import 'app/admin/taboo/index.css';
import {PATH_ADMIN} from "app/routes";

function TabooAdmin() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleCreateCard = useCallback(() => dispatch(openModal(CreateCard)), [dispatch]);
  const isLoadingCards = useFetchCards();
  const isLoadingDifficulties = useFetchDifficulties();

  if (isLoadingCards || isLoadingDifficulties) {
    return <FullPageSpinner />;
  }

  return (
    <div className="AdminTaboo">
      <div className="AdminTabooLinks">
        <Link to={PATH_ADMIN} className="BackToAdmin button medium secondary">
          {t('admin.backToAdmin')}
        </Link>
        <button className="AdminTabooCreateCard button medium primary" onClick={handleCreateCard}>
          <IconPlus className="AdminTabooCreateCardIcon" />
          {t('admin.taboo.cards.createCard')}
        </button>
      </div>
      <div className="AdminTabooCardsWrapper">
        <h1>{t('admin.taboo.cards.title')}</h1>
        <AdminTabooCards />
      </div>
    </div>
  );
}

export default TabooAdmin;
