import config from 'config';
import HttpConflictError from 'core/exceptions/httpConflictError';
import HttpServerError from 'core/exceptions/httpServerError';
import HttpUnprocessableEntity from 'core/exceptions/httpUnprocessableEntity';
import HttpNotFound from 'core/exceptions/httpNotFound';
import HttpUnauthorized from 'core/exceptions/httpUnauthorized';
import getAuthToken from 'app/users/selectors/getAuthToken';

function throwExceptionOnError(body, response) {
  const { status } = response;

  if (status === 409) {
    throw new HttpConflictError(body, response);
  } else if (status === 500) {
    throw new HttpServerError(body, response);
  } else if (status === 422) {
    throw new HttpUnprocessableEntity(body, response);
  } else if (status === 404) {
    throw new HttpNotFound(body, response);
  } else if (status === 403) {
    throw new HttpUnauthorized(body, response);
  }
}

function query(method, path, options = {}) {
  return async (dispatch, getState) => {
    const url = `${config.server.url}${path}`;
    const token = getAuthToken(getState());

    const headers = {
      ...(options.headers ?? {})
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(url, {
      ...options,
      method,
      mode: 'cors',
      credentials: 'same-origin',
      headers,
    });
    const body = await response.json();

    throwExceptionOnError(body, response);

    return body;
  };
}

export function get(path, options = {}) {
  return query('GET', path, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...(options.headers ?? {})
    },
  });
}

export function post(path, data, options = {}) {
  return query('POST', path, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...(options.headers ?? {})
    },
    body: JSON.stringify(data),
  });
}

export function postFormData(path, formData, options = {}) {
  return query('POST', path, {
    ...options,
    body: formData,
  });
}

export function put(path, data, options = {}) {
  return query('PUT', path, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...(options.headers ?? {})
    },
    body: JSON.stringify(data),
  });
}

export function putFormData(path, formData, options = {}) {
  return query('PUT', path, {
    ...options,
    body: formData,
  });
}

export function del(path, data, options = {}) {
  return query('DELETE', path, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...(options.headers ?? {})
    },
    body: JSON.stringify(data),
  });
}

export default { get, post, put, del };
