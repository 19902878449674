import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useParamSelector from 'core/redux/hooks/useParamSelector';
import useFetchInstance from 'app/games/hangedman/hooks/useFetchInstance';
import getInstance from 'app/games/hangedman/selectors/getInstance';
import Desktop from 'app/desktop';
import LoadingScreen from 'app/loadingScreen';
import Loading from 'app/games/hangedman/loading';
import Game from 'app/games/hangedman/game';
import GameTitle from "app/games/hangedman/gameTitle";
import 'app/games/hangedman/index.css';

function HangedmanContent() {
  const { t } = useTranslation();
  const { instanceId } = useParams();
  const instance = useParamSelector(getInstance, instanceId);


  if (!instance) {
    return (
      <div>{t('games.hangedman.instance.notFound')}</div>
    );
  }

  console.log('@HangedmanContent instance', instance);

  return (
    <Game instance={instance}/>
  );
}

function Hangedman() {
  const { t } = useTranslation();
  const { instanceId } = useParams();
  const { isRunning: isLoadingInstance } = useFetchInstance(instanceId);
  const isLoading = isLoadingInstance;

  return (
    <Desktop title={t('games.title')}>
      <div className="Hangedman">
        <GameTitle />
        <LoadingScreen isLoading={isLoading} fallback={<Loading />}>
          <HangedmanContent />
        </LoadingScreen>
      </div>
    </Desktop>
  );
}

export default Hangedman;
