import { useCallback, useEffect, useRef } from 'react';

export default function usePromise() {
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  });

  return useCallback(promise => {
    return new Promise((resolve, reject) => {
      const onFulfilled = value => {
        if (isMountedRef.current) {
          resolve(value);
        }
      };

      const onRejected = error => {
        if (isMountedRef.current) {
          reject(error);
        }
      };

      promise.then(onFulfilled, onRejected);
    });
  }, []);
}
