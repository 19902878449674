import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import getCurrentUser from 'app/users/selectors/getCurrentUser';
import unregisterUser from 'app/users/actions/unregisterUser';
import 'app/users/logout.css';

function Logout() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);

  const logout = () => {
    dispatch(unregisterUser(currentUser.id));
  };

  return (
    <button className="Logout button small secondary onSecondary" onClick={logout}>
      {t('desktop.logout')}
    </button>
  );
}

export default Logout;
