import React from 'react';
import { ToastContainer, cssTransition } from 'react-toastify';
import 'app/notifications/index.css';
import 'react-toastify/dist/ReactToastify.css';

const EaseOutCubic = cssTransition({
  enter: 'NotificationsEaseOutCubicEnter',
  exit: 'NotificationsEaseOutCubicExit',
  duration: 300,
});

const Notifications = () => {
  return (
    <div className="Notifications">
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeButton={false}
        closeOnClick={false}
        rtl={false}
        draggable={true}
        pauseOnHover={true}
        pauseOnFocusLoss={true}
        transition={EaseOutCubic}
      />
    </div>
  );
};

export default Notifications;
