import React from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import isCurrentUserAdmin from "app/users/selectors/isCurrentUserAdmin";
import Desktop from 'app/desktop';
import GameTile from 'app/games/tile';
import 'app/games/index.css';
import { PATH_GAMES_HANGEDMAN_NEW, PATH_GAMES_TABOO_NEW, PATH_GAMES_WHOISIT_NEW} from "app/routes";

function Games() {
  const { t } = useTranslation();
  const isAdmin = useSelector(isCurrentUserAdmin);

  return (
    <Desktop title={t('games.title')}>
      {isAdmin ? (
        <div className="Games">
          <GameTile
            to={PATH_GAMES_TABOO_NEW}
            title={t('games.taboo.title')}
            description={t('games.taboo.description')}
            className="GameTileTaboo"
          />
          <GameTile
            to={PATH_GAMES_WHOISIT_NEW}
            title={t('games.whoisit.title')}
            description={t('games.whoisit.description')}
            className="GameTileWhoIsIt"
          />
          <GameTile
            to={PATH_GAMES_HANGEDMAN_NEW}
            title={t('games.hangedman.title')}
            description={t('games.hangedman.description')}
            className="GameTileHangedMan"
          />
        </div>
      ) : (
        <div className="GamesEmpty">
          <div className="GamesNothingToDo">
            {t('games.nothingToDo')}
          </div>
        </div>
      )}
    </Desktop>
  );
}

export default Games;
