import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import notifyError from 'app/notifications/notifyError';
import notifySuccess from 'app/notifications/notifySuccess';
import createCard from 'app/admin/whoisit/actions/createCard';
import ModalContent from 'app/modal/content';
import CardForm from 'app/admin/whoisit/cards/modal/form';
import {useTranslation} from "react-i18next";

function CreateCard({ closeModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const handleSave = async (name, image) => {
    setLoading(true);

    try {
      await dispatch(createCard(name, image));

      setTimeout(() => {
        notifySuccess(t('admin.whoisit.card.createForm.save.success'));
        setLoading(false);
        closeModal();
      }, 100);
    } catch (exception) {
      setLoading(false);
      notifyError(exception);
    }
  };

  return (
    <ModalContent title={t('admin.whoisit.card.createForm.title')} closeModal={closeModal} loading={isLoading}>
      <CardForm
        submitLabel={t('admin.whoisit.card.createForm.save')}
        onSubmit={handleSave}
      />
    </ModalContent>
  );
}

export default CreateCard;
