import React, { useState} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import startRound from "app/games/hangedman/actions/startRound";
import notifyError from "app/notifications/notifyError";
import 'app/games/hangedman/gameStartRound.css';
import toLocaleUpperCaseFixed from "core/helpers/toLocaleUpperCaseFixed";
import {HANGEDMAN_AVAILABLE_LETTERS} from "app/games/hangedman/constants/keyboard";

function sanitizeWord(word) {
  return [...toLocaleUpperCaseFixed(word)]
    .filter(letter => HANGEDMAN_AVAILABLE_LETTERS.includes(letter))
    .join('')
    .substring(0, 30);
}

const GameStartRound = ({ instance }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [word, setWord] = useState('');

  const onWordChange = (event) => setWord(sanitizeWord(event.target.value));

  const onSubmit = async () => {
    try {
      await dispatch(startRound(instance.id, word));
    } catch (exception) {
      notifyError(exception);
    }
  };

  return (
    <div className="GameStartRound panel">
      <div className="GameStartRoundLabel">
        {t('games.hangedman.startRound.proposeWord')}
      </div>
      <input type="text" value={word} onChange={onWordChange} />
      <div className="GameStartRoundInputLegend">
        {t('games.hangedman.startRound.inputLegend')}
      </div>
      <button className="GameStartRoundSubmitButton button primary large" onClick={onSubmit}>
        {t('games.hangedman.startRound.submitWord')}
      </button>
    </div>
  );
};

export default GameStartRound;
