import Bugsnag from '@bugsnag/js'
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import useWebsocketConnect  from 'core/redux/hooks/useWebsocketConnect';
import useFetch  from 'core/redux/hooks/useFetch';
import fetchUsers from 'app/users/actions/fetchUsers';
import registerUserByToken from 'app/users/actions/registerUserByToken';
import isUserAuthenticated from "app/users/selectors/isUserAuthenticated";
import Routes from 'app/routes';
import Modal from 'app/modal';
import Notifications from 'app/notifications';
import Login from 'app/users/login';
import Spinner from "app/spinner";
import Desktop from "app/desktop";
import 'app/index.css';
import useActionEffect from "core/redux/hooks/useActionEffect";
import getCurrentUser from "./users/selectors/getCurrentUser";

const AppLoading = () => {
  const { t } = useTranslation();

  return (
    <Desktop title={t('games.title')}>
      <div className="AppLoading">
        <Spinner />
      </div>
    </Desktop>
  );
};

const AppRoot = ({ children }) => {
  const isLoadingUsers = useFetch(fetchUsers);

  if (isLoadingUsers) {
    return <AppLoading />;
  }

  return children;
};

const App = () => {
  const { isRunning: isAuthenticating } = useActionEffect(registerUserByToken);
  const isAuthenticated = useSelector(isUserAuthenticated);
  const currentUser = useSelector(getCurrentUser);

  useWebsocketConnect();

  useEffect(() => {
    if (isAuthenticated && currentUser) {
      Bugsnag.setUser(currentUser.id, null, currentUser.name);
    } else {
      Bugsnag.setUser(null, null, null);
    }
  }, [isAuthenticated, currentUser]);

  useEffect(() => {
    // Disable automatic translation of the page
    document.querySelector('html').setAttribute('translate', 'no');;
  }, []);

  if (isAuthenticating) {
    return <AppLoading />;
  }

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <AppRoot>
      <React.StrictMode>
        <div className="App primaryBg">
          <Routes/>
          <Modal />
        </div>
      </React.StrictMode>
      <Notifications />
    </AppRoot>
  );
};

export default App;
