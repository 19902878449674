import { get } from 'core/helpers/fetch';
import {
  FETCH_HANGEDMAN_INSTANCE_STARTED,
  FETCH_HANGEDMAN_INSTANCE_SUCCEED,
  FETCH_HANGEDMAN_INSTANCE_FAILED,
} from 'app/games/hangedman/reducers/instances';

function fetchInstance(id) {
  return async dispatch => {
    dispatch({ type: FETCH_HANGEDMAN_INSTANCE_STARTED, payload: { id } });
    try {
      const instance = await dispatch(get(`/games/hangedman/instances/${id}`));
      dispatch({ type: FETCH_HANGEDMAN_INSTANCE_SUCCEED, payload: { instance } });
      return instance;
    } catch (exception) {
      dispatch({ type: FETCH_HANGEDMAN_INSTANCE_FAILED, payload: { exception } });
      throw exception;
    }
  };
}

export default fetchInstance;
