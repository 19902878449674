import HttpError from "../exceptions/httpError";
import getTranslatedErrorMessageByHttpErrorCode from "./getTranslatedErrorMessageByHttpErrorCode";

export default function getExceptionMessage(exception) {
  if (exception instanceof HttpError && exception.errorCode) {
    return getTranslatedErrorMessageByHttpErrorCode(exception.errorCode, exception.message);
  } else if (exception instanceof Error) {
    return exception.message;
  } else if (typeof exception === 'string') {
    return exception;
  } else if (typeof exception === 'object' && typeof exception.toString === 'function') {
    return exception.toString();
  } else {
    return 'Unknown error';
  }
}
