import getCards from "app/games/whoisit/selectors/getCards";

export default function getCurrentUserCard(state, instance) {
  const currentCardId = state.games.whoisit.currentUserCard[instance.id];

  if (currentCardId == null) {
    return null;
  }

  return getCards(state).find(card => card.id === currentCardId);
}
