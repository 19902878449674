import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import getDifficulties from 'app/games/taboo/selectors/getDifficulties';
import getCategories from "app/games/taboo/selectors/getCategories";
import 'app/admin/taboo/cards/modal/form.css';

function generateNewCardValues(difficulties) {
  return {
    word: '',
    forbidden: '',
    difficulty: '',
    category: '',
  };
}

function generateCardValues(card) {
  return {
    word: card.word,
    forbidden: card.forbidden.join("\n"),
    difficulty: card.difficulty,
    category: card.category,
  };
}

const TYPE_DIFFICULTY = 'difficulty';
const TYPE_CATEGORY = 'category';

function CardForm({ submitLabel, onSubmit, card = null }) {
  const { t } = useTranslation();
  const difficulties = useSelector(getDifficulties);
  const categories = useSelector(getCategories);
  const cardValues = card ? generateCardValues(card) : generateNewCardValues(difficulties);
  const [ values, setValues ] = useState(cardValues);
  const [type, setType] = useState(card?.category ? TYPE_CATEGORY : TYPE_DIFFICULTY);

  const handleInputChange = ({ target: { name, value }}) => setValues({...values, [name]: value });

  const handleSubmit = () => onSubmit({
    type,
    word: values.word,
    forbidden: values.forbidden.length > 0 ? values.forbidden.split("\n") : [],
    difficulty: type === TYPE_DIFFICULTY ? values.difficulty : undefined,
    category: type === TYPE_CATEGORY ? values.category : undefined,
  });

  const changeType = (event) => {
    setType(event.target.value);
  };

  return (
    <div className="CardForm">
      <div className="CardFormWord CardFormInputRow">
        <label htmlFor="word">{t('admin.taboo.card.form.word.label')}</label>
        <input name="word" value={values.word} type="text" onChange={handleInputChange} />
      </div>
      <div className="CardFormForbidden CardFormInputRow">
        <label htmlFor="word">{t('admin.taboo.card.form.forbidden.label')}</label>
        <textarea name="forbidden" value={values.forbidden} onChange={handleInputChange} />
      </div>
      <div className="CardFormDifficultyOrCategory CardFormInputRow">
        <select value={type} onChange={changeType}>
          <option value={TYPE_DIFFICULTY}> {t(`admin.taboo.card.form.type.option.difficulty`)}</option>
          <option value={TYPE_CATEGORY}> {t(`admin.taboo.card.form.type.option.category`)}</option>
        </select>
      </div>
      {type === TYPE_DIFFICULTY && (
        <div className="CardFormDifficulty CardFormInputRow">
          <label htmlFor="word">{t('admin.taboo.card.form.difficulty.label')}</label>
          <select name="difficulty" value={values.difficulty} onChange={handleInputChange}>
            <option key="none" value="">{''}</option>
            {difficulties.map(({ id, slug }) => (
              <option key={id} value={id}>
                {t(`games.taboo.difficulty.${slug}`)}
              </option>
            ))}
          </select>
        </div>
      )}
      {type === TYPE_CATEGORY && (
        <div className="CardFormCategory CardFormInputRow">
          <label htmlFor="word">{t('admin.taboo.card.form.category.label')}</label>
          <select name="category" value={values.category} onChange={handleInputChange}>
            <option key="none" value="">{''}</option>
            {categories.map(({ id, name }) => (
              <option key={id} value={id}>
                {t(`games.taboo.category.${name}`)}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="CardFormSave">
        <button className="CardFormSaveButton button primary" onClick={handleSubmit}>
          {submitLabel}
        </button>
      </div>
    </div>
  );
}

export default CardForm;
