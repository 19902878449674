import { postFormData} from 'core/helpers/fetch';
import { CREATE_CARD_SUCCESS, CREATE_CARD_ERROR } from 'app/games/whoisit/reducers/cards';

function createCard(name, image) {
  return async dispatch => {
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('image', image);
      const createdCard = await dispatch(postFormData(`/admin/whoisit/cards`, formData));
      dispatch({ type: CREATE_CARD_SUCCESS, payload: { card: createdCard }})
    } catch (exception) {
      dispatch({ type: CREATE_CARD_ERROR, payload: { exception }});
      throw exception;
    }
  };
}

export default createCard;
