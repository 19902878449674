import { get } from 'core/helpers/fetch';
import {
  FETCH_CATEGORIES_START,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_ERROR,
} from 'app/games/taboo/reducers/categories';

function fetchCategories() {
  return async dispatch => {
    dispatch({ type: FETCH_CATEGORIES_START });
    try {
      const categories = await dispatch(get('/games/taboo/categories'));
      dispatch({ type: FETCH_CATEGORIES_SUCCESS, payload: { categories } });
      return categories;
    } catch (exception) {
      dispatch({ type: FETCH_CATEGORIES_ERROR, payload: { exception } });
      throw exception;
    }
  };
}

export default fetchCategories;
