import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import openModal from 'app/modal/actions/open';
import CardImage from 'app/admin/whoisit/cards/modal/image';

const ViewImage = ({ name, image }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpenModal = useCallback(() => {
    dispatch(openModal(CardImage, { name, image }));
  }, [dispatch, name, image]);

  return (
    <a href="#" onClick={handleOpenModal}>
      {t('admin.whoisit.cards.column.imageFileLink')}
    </a>
  );
};

export default ViewImage;
