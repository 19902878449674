import React, {useEffect, useState} from "react";
import 'app/games/whoisit/card.css';

const ThreeDotsLoading = () => {
  const [count, setCount] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count === 3 ? 1 : count + 1);
    }, 200);
    return () => clearInterval(interval);
  }, [count, setCount]);

  return '.'.repeat(count);
};

const WhoisitCard = ({ card = null }) => (
  <div className="WhoisitCard card onSecondary">
    <div className="WhoisitCardName">
      {card ? card.name : <ThreeDotsLoading />}
    </div>
    <div className="WhoisitCardImage">
      {card?.image ? <img src={card.image} alt="" /> : <ThreeDotsLoading />}
    </div>
  </div>
);

export default WhoisitCard;
