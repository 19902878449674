import React from 'react';
import {useTranslation} from "react-i18next";
import 'app/games/taboo/selectDifficultyOrCategory.css';
import { ReactComponent as IconCategoryGeneral } from 'assets/icon/category/general.svg';
import { ReactComponent as IconCategoryTransport } from 'assets/icon/category/transport-circulation.svg';
import { ReactComponent as IconCategoryMarketing } from 'assets/icon/category/marketing.svg';
import { ReactComponent as IconCategoryBureau } from 'assets/icon/category/materiel-bureau.svg';
import { ReactComponent as IconCategoryGastronomie } from 'assets/icon/category/gastronomie.svg';
import { ReactComponent as IconCategoryCorrespondance } from 'assets/icon/category/correspondance.svg';
import { ReactComponent as IconCategoryTourisme } from 'assets/icon/category/tourisme.svg';

const CategoryIconsMap = {
  'general': IconCategoryGeneral,
  'transport-circulation': IconCategoryTransport,
  'marketing': IconCategoryMarketing,
  'materiel-bureau': IconCategoryBureau,
  'gastronomie': IconCategoryGastronomie,
  'correspondance': IconCategoryCorrespondance,
  'tourisme': IconCategoryTourisme,
};

function SelectDifficultyOrCategory({
  difficulties,
  categories,
  selectDifficulty,
  selectCategory,
}) {
  const { t } = useTranslation();

  return (
    <div className="SelectDifficultyOrCategory">
      <div className="SelectDifficultyOrCategoryContainer">
        <div className="SelectDifficultyOrCategoryIntro passiveTile">
          {t(`games.taboo.selectFilterIntro`)}
        </div>
        <div className="SelectDifficultyChoices">
          {difficulties.map(difficulty => (
            <div
              className="SelectDifficultyChoice tile onPrimary"
              key={difficulty.slug}
              onClick={() => selectDifficulty(difficulty.id)}
            >
              {t(`games.taboo.difficulty.${difficulty.slug}`)}
              <div className="SelectDifficultyChoiceStars">
                {t(`games.taboo.difficulty.${difficulty.slug}.stars`)}
              </div>
            </div>
          ))}
        </div>
        <div className="SelectCategoryChoices">
          {categories.map(category => {
            const Icon = CategoryIconsMap[category.name];
            return (
              <div
                className="SelectCategoryChoice tile onPrimary"
                key={category.name}
                onClick={() => selectCategory(category.id)}
              >
                <div className="SelectCategoryChoiceText">
                  {t(`games.taboo.category.${category.name}`)}
                </div>
                <div className="SelectCategoryChoiceIcon">
                  <Icon className="SelectCategoryChoiceIconSvg" fill="#fff" />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default SelectDifficultyOrCategory;
