const ABCDEF = Symbol();
const AZERTY = Symbol();
const QWERTZ = Symbol();

const keyboardLayouts = {
  [ABCDEF]: [
    [..."ABCDEFGHIJ"],
    [..."KLMNOPQRST"],
    [..."UVWXYZÄÖÜẞ"],
  ],
  [AZERTY]: [
    [..."AZERTYUIOP"],
    [..."QSDFGHJKLM"],
    [..."WXCVBNÄÖÜẞ"],
  ],
  [QWERTZ]: [
    [..."QWERTZUIOPÜ"],
    [..."ASDFGHJKLÖÄ"],
    [..."YXCVBNMẞ"],
  ],
};

export const HANGEDMAN_KEYBOARD_LAYOUT = keyboardLayouts[ABCDEF];

export const HANGEDMAN_AVAILABLE_LETTERS = HANGEDMAN_KEYBOARD_LAYOUT.flat();
