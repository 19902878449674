import React from 'react';
import { toast } from 'react-toastify';
import ErrorNotification from 'app/notifications/error';
import getExceptionMessage from "../../core/helpers/getExceptionMessage";

export default function notifyError(contentOrException, options = {}) {
  toast.error(
    <ErrorNotification>
      {getExceptionMessage(contentOrException)}
    </ErrorNotification>,
    options
  );
}
