import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {PATH_GAMES, PATH_GAMES_TABOO_INSTANCE} from "app/routes";
import generatePathname from "core/helpers/generatePathname";
import createInstance from 'app/games/taboo/actions/createInstance';
import Desktop from 'app/desktop';
import 'app/games/taboo/createInstance.css';
import GameTitle from "app/games/taboo/gameTitle";

export default function CreateInstance() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const createdInstance = await dispatch(createInstance());
    history.push(generatePathname(PATH_GAMES_TABOO_INSTANCE, { instanceId: createdInstance.id }));
  };

  return (
    <Desktop title={t('games.title')}>
      <div className="TabooCreateInstance">
        <GameTitle />
        <div className="TabooCreateInstanceButtons panel padded">
          <button className="TabooCreateInstanceSubmit button primary xlarge" onClick={handleSubmit}>
            {t('games.taboo.newInstance.submit')}
          </button>
          <Link to={PATH_GAMES} className="TabooCreateInstanceBack BackToGames button secondary">
            {t('games.backToGames')}
          </Link>
        </div>
      </div>
    </Desktop>
  );
}
