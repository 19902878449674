import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { wsConnect } from 'core/websocket/redux/actions';

export default function useWebsocketConnect() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(wsConnect());
  }, [dispatch]);
}
