import React from "react";
import 'app/admin/taboo/cards/table/search.css'
import {useTranslation} from "react-i18next";

const Search = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
  const { t } = useTranslation();

  return (
    <input
      className="AdminTabooCardsSearchInput"
      placeholder={t('admin.taboo.cards.search', { count: preGlobalFilteredRows.length })}
      value={globalFilter || ''}
      onChange={e => {
        setGlobalFilter(e.target.value || undefined)
      }}
    />
  );
};

export default Search;
