import React from 'react';
import { ReactComponent as IconWarning } from 'assets/icon/warning.svg';
import 'app/notifications/error.css';

const Error = ({ children }) => (
  <>
    <IconWarning className="NotificationsErrorIcon" />
    <div>{children}</div>
  </>
);

export default Error;
