import React from 'react';
import { useTranslation } from 'react-i18next';
import Desktop from 'app/desktop';
import 'app/notFound.css';

const Page404 = () => {
  const { t } = useTranslation();

  return (
    <Desktop title={t('games.title')}>
      <div className="Page404">
        <div className="Page404Content">
          <h1>{t('404.notFound.title')}</h1>
          {t('404.notFound.description')}
        </div>
      </div>
    </Desktop>
  );
}

export default Page404;
