import {
  REGISTER_CURRENT_USER_ERROR,
  REGISTER_USER_SUCCESS,
  UNREGISTER_USER_SUCCESS
} from 'app/users/reducers/current';

const initialState = false;

export default function(state = initialState, { type }) {
  switch (type) {
    case REGISTER_USER_SUCCESS: {
      return true;
    }
    case REGISTER_CURRENT_USER_ERROR: {
      return false;
    }
    case UNREGISTER_USER_SUCCESS: {
      return false;
    }
    default:
      return state;
  }
}
