export const FETCH_DIFFICULTIES_START = 'difficulties.fetch.start';
export const FETCH_DIFFICULTIES_SUCCESS = 'difficulties.fetch.success';
export const FETCH_DIFFICULTIES_ERROR = 'difficulties.fetch.error';

const initialState = [];

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_DIFFICULTIES_SUCCESS: {
      const { difficulties } = payload;
      return difficulties;
    }
    default:
      return state;
  }
}
