import React from 'react';
import 'app/users/login/form/errors.css';

const Errors = ({ children }) => (
  <div className="LoginFormError">
    {children}
  </div>
);

export default Errors;
