import React, { useState } from 'react'
import {useDispatch, useSelector} from 'react-redux';
import notifySuccess from 'app/notifications/notifySuccess';
import notifyError from 'app/notifications/notifyError';
import updateCard from 'app/admin/whoisit/actions/updateCard';
import getCard from 'app/games/whoisit/selectors/getCard';
import ModalContent from 'app/modal/content';
import CardForm from 'app/admin/whoisit/cards/modal/form';
import {useTranslation} from "react-i18next";

function EditCard({ id, closeModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const card = useSelector(state => getCard(state, id));

  const handleSave = async (name, image) => {
    setLoading(true);

    try {
      await dispatch(updateCard(id, name, image));

      setTimeout(() => {
        notifySuccess(t('admin.whoisit.card.editForm.save.success'));
        setLoading(false);
        closeModal();
      }, 100);
    } catch (exception) {
      setLoading(false);
      notifyError(exception);
    }
  };

  return (
    <ModalContent title={t('admin.whoisit.card.editForm.title')} closeModal={closeModal} loading={isLoading}>
      <CardForm
        card={card}
        submitLabel={t('admin.whoisit.card.editForm.save')}
        onSubmit={handleSave}
      />
    </ModalContent>
  );
}

export default EditCard;
