import React from 'react';
import { useSelector } from 'react-redux';
import getUsers from 'app/users/selectors/getUsers';
import 'app/connectedUsersTooltip.css';

function ConnectedUsersTooltip() {
  const users = useSelector(getUsers);

  return (
    <div className="ConnectedUsersTooltip">
      {users.map(user => <div key={user.id} className="ConnectedUsersTooltipUser">{user.name}</div>)}
    </div>
  );
}

export default ConnectedUsersTooltip;
