import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next'
import classnames from 'classnames';
import Tooltip from 'rc-tooltip';
import { PATH_ADMIN, PATH_GAMES } from 'app/routes';
import getCurrentUser from 'app/users/selectors/getCurrentUser';
import getUsersCount from 'app/users/selectors/getUsersCount';
import isCurrentUserAdmin from 'app/users/selectors/isCurrentUserAdmin';
import ConnectedUsersTooltip from 'app/connectedUsersTooltip';
import LanguageSwitch from 'app/languageSwitch';
import Logout from 'app/users/logout';
import 'rc-tooltip/assets/bootstrap_white.css';
import 'app/desktop.css';

function Desktop({ className, title = null, children }) {
  const currentUser = useSelector(getCurrentUser);
  const usersCount = useSelector(getUsersCount);
  const isAdmin = useSelector(isCurrentUserAdmin);
  const { t } = useTranslation();

  const withUsers = !!currentUser;
  const withUsersCount= withUsers && usersCount > 0;

  return (
    <div className={classnames('Desktop', { className, withUsers, withUsersCount })}>
      <div className="DesktopHeader secondaryBg">
        <div className="DesktopHeaderLeft">
          {title && <div className="DesktopHeaderTitle">{title}</div>}
          {isAdmin && (
            <div className="DesktopHeaderMenu">
              <NavLink to={PATH_GAMES} className="DesktopHeaderMenuLink" activeClassName="DesktopHeaderMenuLinkActive">
                {t('desktop.menu.games')}
              </NavLink>
              <NavLink to={PATH_ADMIN} className="DesktopHeaderMenuLink" activeClassName="DesktopHeaderMenuLinkActive">
                {t('desktop.menu.admin')}
              </NavLink>
            </div>
          )}
        </div>
        <div className="DesktopHeaderRight">
          {withUsersCount &&
            <Tooltip
              className="DesktopHeaderConnectedUsersTooltip"
              placement="bottom"
              trigger={['hover']}
              overlay={<ConnectedUsersTooltip />}
              overlayClassName="DesktopHeaderConnectedUsersTooltipOverlay"
            >
              <div className="DesktopUsers">
                {t('desktop.activeUsersCount', { count: usersCount })}
              </div>
            </Tooltip>
          }
          {withUsers &&
            <>
              <div className="DesktopCurrentUser">
                <Trans i18nKey="desktop.welcomeUser">
                  Bienvenue <span
                  className="DesktopUsername">{{ name: currentUser.name }}</span>
                </Trans>
              </div>
              <div className="DesktopLogout">
                <Logout />
              </div>
            </>
          }
          <div className="DesktopLanguage">
            <LanguageSwitch />
          </div>
        </div>
      </div>
      <div className="DesktopContent">
        {children}
      </div>
    </div>
  );
}

export default Desktop;
