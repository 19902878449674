import { PICK_TABOO_INSTANCE_CARD_SUCCEED } from 'app/games/taboo/reducers/instances';
import {
  TABOO_FILTER_TYPE_CATEGORY,
  TABOO_FILTER_TYPE_DIFFICULTY
} from "app/games/taboo/reducers/currentUserFilterPerInstance";

const initialState = {};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case PICK_TABOO_INSTANCE_CARD_SUCCEED:
      const newInstanceState = state[payload.instance.id] ?? {
        byDifficulty: state[payload.instanceId]?.byDifficulty ?? {},
        byCategory: state[payload.instanceId]?.byCategory ?? {},
      };

      if (payload.filter.type === TABOO_FILTER_TYPE_DIFFICULTY) {
        newInstanceState.byDifficulty[payload.filter.difficultyId] = payload.cardId;
      } else if (payload.filter.type === TABOO_FILTER_TYPE_CATEGORY) {
        newInstanceState.byCategory[payload.filter.categoryId] = payload.cardId;
      }

      return {
        ...state,
        [payload.instance.id]: newInstanceState,
      };
    default:
      return state;
  }
}
