import { post } from 'core/helpers/fetch';
import {
  PROPOSE_HANGEDMAN_LETTER_STARTED,
  PROPOSE_HANGEDMAN_LETTER_SUCCEED,
  PROPOSE_HANGEDMAN_LETTER_FAILED,
} from 'app/games/hangedman/reducers/instances';

export default function proposeLetter(instanceId, letter) {
  return async dispatch => {
    dispatch({ type: PROPOSE_HANGEDMAN_LETTER_STARTED, payload: { instanceId: instanceId, letter }});
    try {
      const instance = await dispatch(
        post(`/games/hangedman/instances/${instanceId}/proposeLetter`, {
          letter,
        })
      );
      dispatch({
        type: PROPOSE_HANGEDMAN_LETTER_SUCCEED,
        payload: {
          instance,
        }
      });
    } catch (exception) {
      dispatch({ type: PROPOSE_HANGEDMAN_LETTER_FAILED, payload: { instanceId: instanceId, exception } });
      throw exception;
    }
  };
}
