import Spinner from 'app/spinner';
import React from "react";

function Loading() {
  return (
    <div className="WhoisitLoading">
      <Spinner/>
    </div>
  );
}

export default Loading;
