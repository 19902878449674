import Spinner from 'app/spinner';
import React from "react";
import 'app/games/hangedman/loading.css';

function Loading() {
  return (
    <div className="HangedmanLoading">
      <Spinner/>
    </div>
  );
}

export default Loading;
