export const REGISTER_USER_SUCCESS = 'user.register.success';
export const REGISTER_CURRENT_USER_ERROR = 'currentUser.register.error';
export const UNREGISTER_USER_SUCCESS = 'user.unregister.success';

const initialState = null;

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case REGISTER_USER_SUCCESS: {
      return payload.user;
    }
    case REGISTER_CURRENT_USER_ERROR: {
      return state;
    }
    case UNREGISTER_USER_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
}
