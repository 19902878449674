import { getMessageActionType } from 'core/websocket/redux/middleware';
import { STATUS_INITIAL, STATUS_LOADING, STATUS_LOADED, STATUS_FAILED } from 'app/common/status/actions';

export const FETCH_USERS_STARTED = 'users.fetch.started';
export const FETCH_USERS_SUCCEED = 'users.fetch.succeed';
export const FETCH_USERS_FAILED = 'users.fetch.failed';

const WEBSOCKET_USERS_PUSHED = getMessageActionType('update_users');

const initialState = {
  status: STATUS_INITIAL,
  entities: [],
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_USERS_STARTED: {
      return {
        ...state,
        status: STATUS_LOADING,
      }
    }
    case FETCH_USERS_SUCCEED:
    case WEBSOCKET_USERS_PUSHED: {
      return {
        ...state,
        status: STATUS_LOADED,
        entities: payload.users,
      };
    }
    case FETCH_USERS_FAILED: {
      return {
        ...state,
        status: STATUS_FAILED,
      };
    }
    default:
      return state;
  }
}
