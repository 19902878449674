import React from 'react';
// import { useTranslation } from 'react-i18next';
import GameStartRound from "app/games/hangedman/gameStartRound";
import GameRound from "app/games/hangedman/gameRound";
import 'app/games/hangedman/game.css';

function Game({ instance }) {
  return (
    <div className="HangedmanGame">
      {instance.word === '' ? <GameStartRound instance={instance} /> : <GameRound instance={instance} />}
    </div>
  );
}

export default Game;
