import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import openModal from 'app/modal/actions/open';
import EditCard from 'app/admin/taboo/cards/modal/edit';
import { ReactComponent as IconPencil } from 'assets/icon/pencil.svg';
import 'app/admin/taboo/cards/table/editButton.css';
import {useTranslation} from "react-i18next";

const EditButton = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleOpenModal = useCallback(id => dispatch(openModal(EditCard, { id })), [dispatch]);

  return (
    <button
      className="AdminTabooCardsEditButton button secondary small"
      title={t('admin.taboo.cards.editCard')}
      onClick={() => handleOpenModal(id)}
    >
      <IconPencil className="AdminTabooCardsEditButtonIcon" />
    </button>
  );
};

export default EditButton;
