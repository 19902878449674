import React from 'react';
import SvgSpinner from "react-svg-spinner";
import 'app/spinner.css';

function Spinner(props) {
  return <SvgSpinner size="64px" {...props} />;
}

export function FullPageSpinner(props) {
  return (
    <div className="FullPageSpinner">
      <Spinner {...props} />
    </div>
  );
}

export default Spinner;
