import { del } from 'core/helpers/fetch';
import { DELETE_CARD_SUCCESS, DELETE_CARD_ERROR } from 'app/games/whoisit/reducers/cards';

function deleteCard(id) {
  return async dispatch => {
    try {
      await dispatch(del(`/admin/whoisit/cards/${id}`));
      dispatch({ type: DELETE_CARD_SUCCESS, payload: { id }})
    } catch (exception) {
      dispatch({ type: DELETE_CARD_ERROR, payload: { exception }});
      throw exception;
    }
  };
}

export default deleteCard;
