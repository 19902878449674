import Exception from "./exception";
import getTranslatedErrorMessageByHttpErrorCode from "../helpers/getTranslatedErrorMessageByHttpErrorCode";

class HttpError extends Exception {
  constructor(body, response) {
    const message = getTranslatedErrorMessageByHttpErrorCode(body.errorCode, body.message);
    super(message);
    this.message = message;
    this.originalMessage = body.message;
    this.statusCode = body.statusCode;
    this.errorCode = body.errorCode;
    this.response = response;
  }
}

export default HttpError;
