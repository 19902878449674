import { PICK_WHOISIT_INSTANCE_CARD_SUCCEED } from 'app/games/whoisit/reducers/instances';

const initialState = {};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case PICK_WHOISIT_INSTANCE_CARD_SUCCEED:
      return {
        ...state,
        [payload.instance.id]: payload.cardId,
      };
    default:
      return state;
  }
}
