import React from 'react';
import ReactCrop from 'react-image-crop'
import 'app/admin/whoisit/cards/modal/formInputImageCrop.css';

const FormInputImageCrop = ({
  uploadedImagePreviewUrl,
  uploadedImageCropped,
  uploadedImageCroppedPreviewCanvasRef,
  reactCropProps,
  reactCropImgProps,
}) => {
  return (
    <>
      {!!uploadedImagePreviewUrl && (
        <ReactCrop minHeight={100} {...reactCropProps}>
          <img alt="" {...reactCropImgProps} />
        </ReactCrop>
      )}
      {!!uploadedImageCropped && (
        <div className="CardFormImageCropPreview">
          <canvas
            ref={uploadedImageCroppedPreviewCanvasRef}
            style={{
              width: uploadedImageCropped.width,
              height: uploadedImageCropped.height,
            }}
          />
        </div>
      )}
    </>
  );
};

export default FormInputImageCrop;
