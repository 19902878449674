import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import openModal from 'app/modal/actions/open';
import DeleteCard from 'app/admin/taboo/cards/modal/delete';
import { ReactComponent as IconDelete } from 'assets/icon/delete.svg';
import 'app/admin/taboo/cards/table/deleteButton.css';
import {useTranslation} from "react-i18next";

const DeleteButton = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleOpenModal = useCallback(id => dispatch(openModal(DeleteCard, { id })), [dispatch]);

  return (
    <button
      className="AdminTabooCardsDeleteButton button secondary warning small"
      title={t('admin.taboo.cards.deleteCard')}
      onClick={() => handleOpenModal(id)}
    >
      <IconDelete className="AdminTabooCardsDeleteButtonIcon" />
    </button>
  );
};

export default DeleteButton;
