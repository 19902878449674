import { post } from 'core/helpers/fetch';
import {
  PICK_TABOO_INSTANCE_CARD_STARTED,
  PICK_TABOO_INSTANCE_CARD_SUCCEED,
  PICK_TABOO_INSTANCE_CARD_FAILED,
} from 'app/games/taboo/reducers/instances';

export default function pickInstanceCard(instanceId, filter) {
  return async dispatch => {
    dispatch({ type: PICK_TABOO_INSTANCE_CARD_STARTED, payload: { instanceId: instanceId, filter }});
    try {
      const { instance, cardId } = await dispatch(
        post(`/games/taboo/instances/${instanceId}/pickCard`, {
          difficulty: filter.difficultyId,
          category: filter.categoryId
        })
      );
      dispatch({
        type: PICK_TABOO_INSTANCE_CARD_SUCCEED,
        payload: {
          instance,
          cardId,
          filter,
        }
      });
      return cardId;
    } catch (exception) {
      dispatch({ type: PICK_TABOO_INSTANCE_CARD_FAILED, payload: { instanceId: instanceId, exception } });
      throw exception;
    }
  };
}
