import { UNREGISTER_USER_SUCCESS } from 'app/users/reducers/current';
import { del } from 'core/helpers/fetch';

export default function unregisterUser(id) {
  return async dispatch => {
    const createdUser = await dispatch(del('/auth', { id }));

    dispatch({ type: UNREGISTER_USER_SUCCESS });

    return createdUser;
  };
}
