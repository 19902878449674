import { post } from 'core/helpers/fetch';
import {
  PICK_WHOISIT_INSTANCE_CARD_STARTED,
  PICK_WHOISIT_INSTANCE_CARD_SUCCEED,
  PICK_WHOISIT_INSTANCE_CARD_FAILED,
} from 'app/games/whoisit/reducers/instances';

export default function pickInstanceCard(instanceId) {
  return async dispatch => {
    dispatch({ type: PICK_WHOISIT_INSTANCE_CARD_STARTED, payload: { instanceId }});
    try {
      const { instance, cardId } = await dispatch(
        post(`/games/whoisit/instances/${instanceId}/pickCard`)
      );
      dispatch({ type: PICK_WHOISIT_INSTANCE_CARD_SUCCEED, payload: { instance, cardId } });
      return cardId;
    } catch (exception) {
      dispatch({ type: PICK_WHOISIT_INSTANCE_CARD_FAILED, payload: { instanceId, exception } });
      throw exception;
    }
  };
}
