import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Desktop from 'app/desktop';
import LoginByName, { PAGE_LOGIN_BY_NAME } from 'app/users/login/loginByName';
import LoginByCredentials, { PAGE_LOGIN_BY_CREDENTIALS } from 'app/users/login/loginByCredentials';
import CreateCredentials, { PAGE_CREATE_CREDENTIALS } from 'app/users/login/createCredentials';
import 'app/users/login/index.css';

const LoginContent = () => {
  const [ currentPage, setCurrentPage ] = useState(PAGE_LOGIN_BY_NAME);

  if (currentPage === PAGE_LOGIN_BY_CREDENTIALS) {
    return <LoginByCredentials changePage={setCurrentPage} />;
  } else if (currentPage === PAGE_CREATE_CREDENTIALS) {
    return <CreateCredentials changePage={setCurrentPage} />;
  }

  return <LoginByName changePage={setCurrentPage} />;
};

const Login = () => {
  const { t } = useTranslation();

  return (
    <Desktop title={t('login.title')} className="LoginPage">
      <div className="Login">
        <LoginContent />
      </div>
    </Desktop>
  );
};

export default Login;
