import { STATUS_LOADED, STATUS_LOADING } from 'app/common/status/actions';
import { getMessageActionType } from "core/websocket/redux/middleware";

export const FETCH_HANGEDMAN_INSTANCE_STARTED = 'games.hangedman.instance.fetch.started';
export const FETCH_HANGEDMAN_INSTANCE_SUCCEED = 'games.hangedman.instance.fetch.succeed';
export const FETCH_HANGEDMAN_INSTANCE_FAILED = 'games.hangedman.instance.fetch.failed';

export const CREATE_HANGEDMAN_INSTANCE_STARTED = 'games.hangedman.instance.create.started';
export const CREATE_HANGEDMAN_INSTANCE_SUCCEED = 'games.hangedman.instance.create.succeed';
export const CREATE_HANGEDMAN_INSTANCE_FAILED = 'games.hangedman.instance.create.failed';

export const START_HANGEDMAN_ROUND_STARTED = 'games.hangedman.instance.startRound.started';
export const START_HANGEDMAN_ROUND_SUCCEED = 'games.hangedman.instance.startRound.succeed';
export const START_HANGEDMAN_ROUND_FAILED = 'games.hangedman.instance.startRound.failed';

export const END_HANGEDMAN_ROUND_STARTED = 'games.hangedman.instance.endRound.started';
export const END_HANGEDMAN_ROUND_SUCCEED = 'games.hangedman.instance.endRound.succeed';
export const END_HANGEDMAN_ROUND_FAILED = 'games.hangedman.instance.endRound.failed';

export const PROPOSE_HANGEDMAN_LETTER_STARTED = 'games.hangedman.instance.proposeLetter.started';
export const PROPOSE_HANGEDMAN_LETTER_SUCCEED = 'games.hangedman.instance.proposeLetter.succeed';
export const PROPOSE_HANGEDMAN_LETTER_FAILED = 'games.hangedman.instance.proposeLetter.failed';

const WEBSOCKET_HANGEDMAN_INSTANCE_UPDATED = getMessageActionType('games.hangedman.instance_updated');

function findInstanceIndexById(state, id) {
  return state.findIndex(instance => instance.entity.id === id);
}

function updateInstance(state, instance, mergeEntity = false) {
  const instanceIndex = findInstanceIndexById(state, instance.entity.id);

  if (instanceIndex !== -1) {
    const newState = [...state];
    if (mergeEntity) {
      newState[instanceIndex] = {
        ...(newState[instanceIndex] ?? {}),
        ...instance,
        entity: {
          ...(newState[instanceIndex]?.entity ?? {}),
          ...(instance.entity ?? {}),
        }
      };
    } else {
      newState[instanceIndex] = {...newState[instanceIndex], ...instance};
    }
    return newState;
  }

  return [...state, { ...instance } ];
}

function setInstanceProperty(state, instanceId, property, value) {
  const instanceIndex = findInstanceIndexById(state, instanceId);

  if (instanceIndex === -1) {
    return state;
  }

  const newState = [...state];
  newState[instanceIndex] = { ...newState[instanceIndex], [property]: value };
  return newState;
}

const initialState = [];

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_HANGEDMAN_INSTANCE_STARTED:
      return updateInstance(state, {
        status: STATUS_LOADING,
        entity: { id: payload.id }, // @todo merge ?
      });
    case CREATE_HANGEDMAN_INSTANCE_SUCCEED:
    case FETCH_HANGEDMAN_INSTANCE_SUCCEED:
    case START_HANGEDMAN_ROUND_SUCCEED:
    case END_HANGEDMAN_ROUND_SUCCEED:
      return updateInstance(state, {
        status: STATUS_LOADED,
        entity: payload.instance,
      });
    case PROPOSE_HANGEDMAN_LETTER_SUCCEED:
      return updateInstance(state, {
        status: STATUS_LOADED,
        entity: payload.instance,
      });
    case WEBSOCKET_HANGEDMAN_INSTANCE_UPDATED:
      console.log('@WEBSOCKET_HANGEDMAN_INSTANCE_UPDATED payload', payload);
      return updateInstance(
        state,
        {
          status: STATUS_LOADED,
          entity: payload.instance,
        },
        true
      );
    default:
      return state;
  }
}
