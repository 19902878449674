import { post } from 'core/helpers/fetch';
import { CREATE_CARD_SUCCESS, CREATE_CARD_ERROR } from 'app/games/taboo/reducers/cards';

function createCard(values) {
  return async dispatch => {
    try {
      const createdCard = await dispatch(post(`/admin/taboo/cards`, values));
      dispatch({ type: CREATE_CARD_SUCCESS, payload: { card: createdCard }})
    } catch (exception) {
      dispatch({ type: CREATE_CARD_ERROR, payload: { exception }});
      throw exception;
    }
  };
}

export default createCard;
