import { post } from 'core/helpers/fetch';
import {
  CREATE_HANGEDMAN_INSTANCE_STARTED,
  CREATE_HANGEDMAN_INSTANCE_SUCCEED,
  CREATE_HANGEDMAN_INSTANCE_FAILED,
} from 'app/games/hangedman/reducers/instances';

function createInstance() {
  return async dispatch => {
    dispatch({ type: CREATE_HANGEDMAN_INSTANCE_STARTED });
    try {
      const createdInstance = await dispatch(post('/games/hangedman/instances'));
      dispatch({ type: CREATE_HANGEDMAN_INSTANCE_SUCCEED, payload: { instance: createdInstance } });
      return createdInstance;
    } catch (exception) {
      dispatch({ type: CREATE_HANGEDMAN_INSTANCE_FAILED, payload: { exception } });
      throw exception;
    }
  };
}

export default createInstance;
