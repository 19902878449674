import { CLOSE_MODAL, OPEN_MODAL } from 'app/modal/actions';

const initialState = {
  open: false,
  component: null,
  props: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        open: true,
        component: action.component,
        props: action.props,
      };
    case CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
}
