import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import useGameCanvas from "app/games/hangedman/useGameCanvas";
import proposeLetter from "app/games/hangedman/actions/proposeLetter";
import endRound from "app/games/hangedman/actions/endRound";
import notifyError from "app/notifications/notifyError";
import isCurrentUserAdmin from "app/users/selectors/isCurrentUserAdmin";
import { HANGEDMAN_KEYBOARD_LAYOUT } from "app/games/hangedman/constants/keyboard";
import 'app/games/hangedman/gameRound.css';
import sleep from "../../../core/helpers/sleep";

const KeyboardLetter = ({ letter, onClick, interactive, proposed, proposing }) => {
  const handleClick = () => {
    if (interactive && !proposed && !proposing) {
      onClick(letter);
    }
  };

  const classNames = ['HangedmanKeyboardLetter'];
  if (proposed) {
    classNames.push('proposed');
  } else if (interactive) {
    classNames.push('interactive');
    if (proposing) {
      classNames.push('proposing');
    }
  }

  return (
    <button className={classNames.join(' ')} onClick={handleClick}>
      {letter}
    </button>
  );
};

const GameRound = ({ instance }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAdmin = useSelector(isCurrentUserAdmin);
  const { canvasProps } = useGameCanvas(instance);
  const [isEndingRound, setIsEndingRound] = useState(false);
  const [submittingLetter, setSubmittingLetter] = useState(null);

  const word = instance.originalWord ? instance.originalWord : instance.word;

  const isOwner = !!instance.originalWord;
  const isGameWon = instance.remainingTries > 0 && !instance.word.includes('_');
  const isGameLost = !isGameWon && instance.remainingTries === 0;
  const isAllowedToEndRound = isAdmin || isOwner || isGameLost || isGameWon;

  const onLetterClick = async (letter) => {
    if (submittingLetter) {
      return;
    }
    try {
      setSubmittingLetter(letter);
      await Promise.all([
        dispatch(proposeLetter(instance.id, letter)),
        sleep(800),
      ]);
    } catch (exception) {
      notifyError(exception);
    } finally {
      setSubmittingLetter(null);
    }
  };

  const onEndRoundClick = async (letter) => {
    if (isAllowedToEndRound) {
      try {
        setIsEndingRound(true);
        dispatch(endRound(instance.id, letter));
      } catch (exception) {
        notifyError(exception);
      } finally {
        setIsEndingRound(false);
      }
    }
  };

  const classNames = ['HangedmanRound'];
  if (isGameWon) {
    classNames.push('HangedmanRoundWon');
  } else if (isGameLost) {
    classNames.push('HangedmanRoundLost');
  }
  if (isOwner) {
    classNames.push('HangedmanRoundOwner');
  } else {
    classNames.push('HangedmanRoundPlayer');
  }

  return (
    <div className={classNames.join(' ')}>
      <div className="HangedmanGameBoard panel">
        <div className="HangedmanGameCanvas">
          <canvas {...canvasProps} />
        </div>
        <div className="HangedmanPanel">
          <div className="HangedmanWordDiscovered">
            {[...word].map((letter, index) => {
              const classNames = ['HangedmanWordDiscoveredLetter'];
              if (instance.proposedLetters.includes(letter)) {
                classNames.push('discovered');
              } else if (letter === '_') {
                classNames.push('empty');
              }
              return (
                <div className={classNames.join(' ')} key={index}>
                  {letter}
                </div>
              )
            })}
          </div>
          <div className="HangedmanKeyboard">
            {HANGEDMAN_KEYBOARD_LAYOUT.map((rowLetters) => (
              <div className="HangedmanKeyboardLayoutRow">
                {rowLetters.map(letter => (
                  <KeyboardLetter
                    letter={letter}
                    onClick={onLetterClick}
                    interactive={isOwner && !isGameWon && !isGameLost && !submittingLetter}
                    proposed={instance.proposedLetters.includes(letter)}
                    proposing={submittingLetter === letter}
                  />
                ))}
              </div>
            ))}
          </div>
          <button
            className="HangedmanEndRoundButton button primary large"
            onClick={onEndRoundClick}
            disabled={!isAllowedToEndRound || isEndingRound}
          >
            {t('games.hangedman.round.endRoundButton')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GameRound;
