import createWebsocket from 'core/websocket/createWebsocket';
import * as actions from 'core/websocket/redux/actions';
import { WEBSOCKET_CONNECT, WEBSOCKET_DISCONNECT, WEBSOCKET_SERVER_PING, WEBSOCKET_SEND} from 'core/websocket/redux/actions';
import { WEBSOCKET_DISCONNECTED } from 'core/websocket/redux/actions';
import { wsServerPing } from 'core/websocket/redux/actions';

export function getMessageActionType(messageType) {
  return `websocket.${messageType}`;
}

const onOpen = store => (event) => {
  store.dispatch(actions.wsConnected(event.target.url));
};

const onClose = store => () => {
  store.dispatch(actions.wsDisconnected());
};

const onMessage = store => (event) => {
  const { type, ...payload } = JSON.parse(event.data);

  console.debug('[Websocket] Receiving server message', payload);

  if (type === 'ping') {
    store.dispatch(wsServerPing());
  } else {
    store.dispatch({ type: getMessageActionType(type), payload });
  }
};

let socket = null;

function websocketMiddleware(store) {
  return next => action => {
    const { type, payload } = action;

    switch (type) {
      case WEBSOCKET_CONNECT:
        // connect to the remote host
        socket = createWebsocket();

        // websocket handlers
        socket.addEventListener('message', onMessage(store));
        socket.addEventListener('close', onClose(store));
        socket.addEventListener('open', onOpen(store));
        break;
      case WEBSOCKET_DISCONNECTED:
        console.debug('[Websocket] Disconnected.');
        socket = null;
        break;
      case WEBSOCKET_DISCONNECT:
        console.debug('[Websocket] Disconnecting from server.');
        socket = null;
        break;
      case WEBSOCKET_SERVER_PING:
        console.debug('[Websocket] Sending pong');
        if (socket) {
          socket.send(JSON.stringify({ command: 'pong' }));
        }
        break;
      case WEBSOCKET_SEND:
        console.debug('[Websocket] Sending a message', payload);
        socket.send(JSON.stringify({ command: payload.command, payload: payload.payload }));
        break;
      default:
        return next(action);
    }
  };
}

export default websocketMiddleware;
