import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useParamSelector from 'core/redux/hooks/useParamSelector';
import useFetchInstance from 'app/games/taboo/hooks/useFetchInstance';
import useFetchCards from 'app/games/taboo/hooks/useFetchCards';
import useFetchDifficulties from 'app/games/taboo/hooks/useFetchDifficulties';
import useFetchCategories from 'app/games/taboo/hooks/useFetchCategories';
import difficultySelected from 'app/games/taboo/actions/difficultySelected';
import resetFilter from 'app/games/taboo/actions/resetFilter';
import categorySelected from 'app/games/taboo/actions/categorySelected';
import getDifficulties from 'app/games/taboo/selectors/getDifficulties';
import getCategories from 'app/games/taboo/selectors/getCategories';
import getInstance from 'app/games/taboo/selectors/getInstance';
import getInstanceFilter from "app/games/taboo/selectors/getInstanceFilter";
import Desktop from 'app/desktop';
import LoadingScreen from 'app/loadingScreen';
import Loading from 'app/games/taboo/loading';
import Game from 'app/games/taboo/game';
import GameTitle from 'app/games/taboo/gameTitle';
import SelectDifficultyOrCategory from 'app/games/taboo/selectDifficultyOrCategory';


import 'app/games/taboo/index.css';

function TabooContent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { instanceId } = useParams();
  const instance = useParamSelector(getInstance, instanceId);
  const difficulties = useSelector(getDifficulties);
  const categories = useSelector(getCategories);

  const currentFilter = useParamSelector(getInstanceFilter, instanceId);

  const selectDifficulty = (difficultyId) => {
    dispatch(difficultySelected(instanceId, difficultyId));
  };

  const selectCategory = (categoryId) => {
    dispatch(categorySelected(instanceId, categoryId));
  };

  const changeFilter = () => {
    dispatch(resetFilter(instanceId));
  };

  if (!instance) {
    return (
      <div>{t('games.taboo.instance.notFound')}</div>
    );
  }

  if (!currentFilter) {
    return (
      <SelectDifficultyOrCategory
        difficulties={difficulties}
        categories={categories}
        selectDifficulty={selectDifficulty}
        selectCategory={selectCategory}
      />
    );
  }

  return (
    <Game
      instance={instance}
      filter={currentFilter}
      changeFilter={changeFilter}
    />
);
}

function Taboo() {
  const { t } = useTranslation();
  const { instanceId } = useParams();
  const { isRunning: isLoadingInstance } = useFetchInstance(instanceId);
  const isLoadingCards = useFetchCards();
  const isLoadingDifficulties = useFetchDifficulties();
  const isLoadingCategories = useFetchCategories();
  const isLoading = isLoadingInstance || isLoadingCards || isLoadingDifficulties || isLoadingCategories;

  return (
    <Desktop title={t('games.title')}>
      <div className="Taboo">
        <GameTitle />
        <LoadingScreen isLoading={isLoading} fallback={<div className="TabooLoading"><Loading /></div>}>
          <TabooContent />
        </LoadingScreen>
      </div>
    </Desktop>
  );
}

export default Taboo;
